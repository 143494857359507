import React, { Component } from 'react';
import Header from '../../Layout/header3';
import Footer from '../../Layout/footer4';
import PageTitle from '../../Layout/PageTitle';
import SidebarEducation from '../../Element/SidebarServicesEducationalServices';
import {Link} from 'react-router-dom';
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';


// import TabStyle2 from './../ShortCode/TabStyle1';

import Counter from '../../Element/Counter';
import HomeOwlSlider2 from '../../Element/HomeOwlSlider2';
// import TabStyle1 from './../ShortCode/TabStyle1';
import { TabPane } from 'reactstrap';


//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
//import {Link} from "react-router-dom";

class OnlineClasses extends Component {
    render() {
        return (

            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Educational Services' activeMenu='Online Classes' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                
                                            </div>
                                            <div className="et_pb_text_inner">
                                            <TabPane tabId="1">
									<p className="m-b10">
                                    Dream’s Academy is a global venture boasting Clients & Students from all over India and the world. Our Online Classes for IELTS, PTE & Language Classes (French Dutch Arabic Spanish) are available for you in every corner of the world.Just because it’s virtual doesn’t mean you are missing out on anything! 


									</p>
									<p>
                                    We transfer the same level of education and results onto our virtual platforms as well. We host numerous students across the USA, Canada, Australia, New Zealand, and many other countries and help them clear their second round immigrations tests and more.
									</p>
                                    Achieve your dream score sitting at home today!
									<table className="table table-bordered" >
										<tr>
											<td>Weekly Mock Tests & Latest exams discussions</td>
											<td>Multiple Batches for different time Zones</td>
											<td>1-on-1 Speaking sessions</td>
										</tr>
										{/* <tr>
											<td>Conversational Skills</td>
											<td>Interview Skills</td>
											<td>Idioms, proverbs & proficient skills</td>
										</tr> */}
										
									</table>

								</TabPane>
                                <br/>
                                <br/>
                                <div>
                                    <Link to ={"/contact"} className="site-button outline outline-2 button-lg">Contact us</Link>
                                
                                </div>
                                                {/* <TabStyle1></TabStyle1> */}
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <SidebarEducation />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Our Services --> */}
                <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Your Dream Destination</h2>
                                    <h5 className="box-title m-tb0">The dream destination of progress & development will be achieved by imparting latest education to the youth<span className="bg-primary"></span></h5>


                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div>
                        
                <div className="choses-info text-black" >
                                <Counter />
                                
                            </div>
                <Footer />
            </>
        )
    }
}
export default OnlineClasses;
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

//import test1 from './../../../images/testimonials/pic1.jpg';
//import test2 from './../../../images/testimonials/pic2.jpg';
import test3 from './../../../images/testimonials/pic3.jpg';

/* const commentBlog = [
	{ image: test3, }, { image: test3, }, { image: test3, }, { image: test3, }, { image: test3, }, { image: test3, }
]; */

const TabStyle2 = (props) => {
	const [activeTab, setActiveTab] = useState('1');

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	return (
		<Fragment>

			<div className="row">
				<div className="col-md-12">
					<div className="dlab-tabs product-description tabs-site-button">
						<ul className="nav nav-tabs">
							<li>
								<Link to={"#"} className={classnames({ active: activeTab === '1' }) + ''} onClick={() => { toggle('1'); }}><i className="fa fa-cog"></i> Study Visa</Link>
							</li>
							<li>
								<Link to={"#"} className={classnames({ active: activeTab === '2' }) + ''} onClick={() => { toggle('2'); }}><i className="fa fa-cog"></i> Tourist Visa</Link>
							</li>
							<li>
								<Link to={"#"} className={classnames({ active: activeTab === '3' }) + ''} onClick={() => { toggle('3'); }}><i className="fa fa-cog"></i> Super Visa</Link>
							</li>
							<li>
								<Link to={"#"} className={classnames({ active: activeTab === '4' }) + ''} onClick={() => { toggle('4'); }}><i className="fa fa-cog"></i> Work Visa</Link>
							</li>
							<li>
								<Link to={"#"} className={classnames({ active: activeTab === '5' }) + ''} onClick={() => { toggle('5'); }}><i className="fa fa-cog"></i> PR</Link>
							</li>
							<li>
								<Link to={"#"} className={classnames({ active: activeTab === '6' }) + ''} onClick={() => { toggle('6'); }}><i className="fa fa-cog"></i>Spouse Visa</Link>
							</li>
						</ul>

						<TabContent activeTab={activeTab}>

							<TabPane tabId="1">
								<p className="m-b10">
									Want to acquire holistic education from the leading universities worldwide? Can’t navigate through the massive paper trail and formalities? Let dream’z simplify the Student Visa process 	for you and get you to your dream destination without a fuss.
								</p>
								<p>
									Our seasoned Visa experts & counsellors help you understand the visa process step-by-step and take care of the formalities so you don’t have to. We are proud of our transparent process and always keep you updated with fast results.
								</p>
								Call us today to empower your overseas endeavours!

								<table className="table table-bordered" >
									<tr>
										<td>High Success Rates</td>
										<td>Transparent & Fast Process</td>
									</tr>
									<tr>
										<td>Handholding support in formalities</td>
										<td>Previously Refused cases accepted</td>
									</tr>

								</table>

							</TabPane>

							<TabPane tabId="2">
								<p>
									The world is waiting to be explored! Tick all the destinations in your wishlist and take on new adventures. Here at Dream’z we fulfil your travel plans and help you navigate through the visa process and take care of the documentation while you pack your bags!
								</p>
								<p>
									Our seasoned Visa experts & counsellors help you understand the visa process step-by-step and take care of the formalities so you don’t have to. We are proud of our transparent process and always keep you updated with fast results
								</p>
								Call us today to empower your overseas endeavours!

								<table className="table table-bordered" >
									<tr>
										<td>High Success Rates</td>
										<td>Transparent & Fast Process</td>
									</tr>
									<tr>
										<td>Handholding support in formalities</td>
										<td>Previously Refused cases accepted</td>
									</tr>

								</table>
							</TabPane>

							<TabPane tabId="3">
								<p>
									Are you a parent or grandparent of a permanent resident and want to live with them? The super visa is a multi-entry visa that provides multiple entries for a period up to 10 years allowing an individual to stay abroad for up to 5 years at a time. 
								</p>
								<p>
									Our seasoned Visa experts & counsellors help you understand the visa process step-by-step and take care of the formalities so you don’t have to. We are proud of our transparent process and always keep you updated with fast results.
								</p>
									 	Call us today to empower your overseas endeavours!

								<table className="table table-bordered" >
									<tr>
										<td>High Success Rates</td>
										<td>Transparent & Fast Process</td>
									</tr>
									<tr>
										<td>Handholding support in formalities</td>
										<td>Previously Refused cases accepted</td>
									</tr>

								</table>
							</TabPane>

							<TabPane tabId="4">
								<p>
									Kickstart your professional career abroad with an employment visa to your desired destination. Get the highest-paying jobs and work with leading names and take your career to new heights abroad. If you have attributes desired to strengthen the international workforce, a work visa is what you need.
								</p>
								<p>
									Our seasoned Visa experts & counsellors help you understand the visa process step-by-step and take care of the formalities so you don’t have to. We are proud of our transparent process and always keep you updated with fast results.
								</p>
									Call us today to empower your overseas endeavours!	


								<table className="table table-bordered" >
									<tr>
										<td>High Success Rates</td>
										<td>Transparent & Fast Process</td>
									</tr>
									<tr>
										<td>Handholding support in formalities</td>
										<td>Previously Refused cases accepted</td>
									</tr>

								</table>
							</TabPane>

							<TabPane tabId="5">
								<p>
									Do you dream of settling abroad without going through the hassle of a study or work visa? Become a permanent resident directly in the country of your choice and give your life a new start. Let Dream’z Academy handle the rest for you! 
								</p>
								<p>
									Our seasoned Visa experts & counsellors help you understand the visa process step-by-step and take care of the formalities so you don’t have to. We are proud of our transparent process and always keep you updated with fast results
								</p>
								Call us today to empower your overseas endeavours!
								<br />

								<table className="table table-bordered" >
									<tr>
										<td>High Success Rates</td>
										<td>Transparent & Fast Process</td>
									</tr>
									<tr>
										<td>Handholding support in formalities</td>
										<td>Previously Refused cases accepted</td>
									</tr>

								</table>
							</TabPane>

							<TabPane tabId="6">
								<p>
									No need to wait months or years to meet your spouse living abroad. Let Dream’z enable you to live with your partner abroad with a spouse visa. Whether your better half is a student, on a work visa, permanent resident or a citizen abroad, we can help you settle with them seamlessly.and help you enhance your linguistic skills and master various levels in fun & interesting ways.
								</p>
								<p>
									Our seasoned Visa experts & counsellors help you understand the visa process step-by-step and take care of the formalities so you don’t have to. We are proud of our transparent process and always keep you updated with fast results
								</p>

								Call us today to empower your overseas endeavours!
								<br />

								<table className="table table-bordered" >
									<tr>
										<td>High Success Rates</td>
										<td>Transparent & Fast Process</td>
									</tr>
									<tr>
										<td>Handholding support in formalities</td>
										<td>Previously Refused cases accepted</td>
									</tr>

								</table>
							</TabPane>

						
						</TabContent>
					</div>
				</div>
			</div>

		</Fragment>
	)

}
export default TabStyle2;
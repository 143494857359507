import React, { Component } from 'react';
//import { Link } from 'react-router-dom';
import Header from './../../Layout/header3';
import Footer from './../../Layout/footer4';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
//import TestimonialCarousel from './../../Element/TestimonialCarousel';
//import FormStyle from './../../Element/FormStyle';

//Images
import  about9 from './../../../images/about/pic9.jpg';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/pic1.jpg';
import services2 from './../../../images/our-services/pic2.jpg';
//import bg1 from './../../../images/background/bg1.jpg';
import bg1 from './../../../images/background/tcm-global-contact-background.jpg';

//import bnr from './../../../images/banner/bnr2.jpg';
import bnr3 from './../../../images/pattern/pic1.jpg';

/* import mem1 from './../../../images/our-work/neetu-prashar-CEO.jpg';
import mem2 from './../../../images/our-work/rakesh-prashar-COO.jpg';
import mem3 from './../../../images/our-work/jaskaran-kaur.jpg';
import mem4 from './../../../images/our-work/madhuri.jpg';
import mem5 from './../../../images/our-work/sofia-kanwar.jpg';
import mem6 from './../../../images/our-work/jaspreet-kaur.jpg';
import mem7 from './../../../images/our-work/jaspreet.jpg';
import mem8 from './../../../images/our-work/ankita-bhandari.jpg'; */

/* const imageblog =[
	{image: mem1,title:'CEO',  body:'Neetu Prashar', designation:'MA(English)'},
	{image: mem2,title:'COO', body: 'Rakesh Prashar', designation:'LLB, MBA'},
	{image: mem3,title:'HOD IELTS', body: 'Jaskaran Kaur',designation:'M Com'},
    {image: mem4,title:'HOD PTE', body: 'Madhuri', designation:'MCA'},
    {image: mem5,title:'Senior IELTS Trainer ', body: 'Sofia Kanwar', designation:'MBA'},
    {image: mem6,title:'In-charge Speaking ', body: 'Jaspreet Kaur', designation:'MA (English pursuing)'},
    {image: mem7,title:'HOD Admin', body: 'Jaspreet Kaur', designation:'BA'},
    {image: mem8,title:'Visa Department ', body:'Ankita Bhandari', designation:'MCA'},
]; */
class Aboutus1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					{/*<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>*/}
					{/*	<PageTitle motherMenu='Company Profile' activeMenu='Company Profile' />*/}
					{/*</div>	*/}
					<div className="bg-black"  >
						<PageTitle motherMenu='Company Profile' activeMenu='Company Profile' style={{ padding:"5px" }} />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">About Dreamz' Academy</h2>
                               {/*     <h2 className="box-title m-tb0">Why you should choose Us?<span className="bg-primary"></span></h2>
                                    <p>TCM Global - Migration & Education Solutions is the best immigration consulting company who delivers seamless end-to-end guidance to aspiring clients in achieving their life goals without facing any hurdles.
                                        By making good use of their affluent experience in the immigration process, our consultants cater a clear picture to the clients regarding the entire immigration process and guide them in clearing the related complexities in a hassle free way.

                                        We follow a unique and standardised approach in providing immigration services like Permanent Residence, Temporary Residence Visa that help our clients to get landed in the perfect destination of opportunities according to their profile.

                                        Our immigration consulting service wings got widespread to key opportunity regions like Canada, Australia, New Zealand, UK & USA and we have quite been successful in immigrating clients of different field of interest since our inception due to the unique approach practised by us.</p>
                               */} </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8 fly-box-ho">
                                        <div className="about-box left row m-lr0 ">

                                            <div className="col-lg-12 text-black text-justify">
                                                <h3 className="text-gray-dark">Our Journey</h3>
                                                <p>
                                                    What started from a bunch of students in a room 5+ years ago has now successfully transitioned to thousands of students with 50+ trainers across three cities. This wouldn’t have been possible without our students, clients and of course our team.

                                                    In all these years we’ve grown and how! But what has remained persistent throughout is our dedication to our craft and unwavering quality. All this has enabled us to deliver astonishing results time and time again. 

                                                    Like our name, this institution was a mere dream years ago and if not for our founders’ conviction, it would have remained so. Starting from IELTS preparation, we now have other educational exams and visa services under our belt. 

                                                    To many more victories ahead!
                                                </p>

                                                <p className="m-b0">Our immigration consulting service wings got widespread to key opportunity regions like Canada, Australia, New Zealand, UK & USA and we have quite been successful in immigrating clients of different field of interest since our inception due to the unique approach practised by us.</p>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="section-full content-inner" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                            {/* <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-uppercase m-b10">Our Team</h2>
                                    <p> </p>
                                </div>
                                <div className="row text-center">
									{imageblog.map((item,index)=>(
										<div className="col-lg-3 col-md-3 col-sm-9 m-b20" key={index}>
											<div className="dlab-box">
												<div className="dlab-media dlab-img-effect rotate imgbrdr" > 
                                                <Link to ={"#"}><img src={item.image} alt="" /></Link> 
                                                </div>
												<div className="dlab-info p-a10 border-1 bg-white">
													<h1 className="dlab-title m-t0">
                                                        {/*<Link to ={"#"}>{item.title }</Link>* /}
                                                        <h3>{item.body}</h3>
                                                        
                                                        {/*<h4><Link to ={"#"}>{item.body}</Link></h4> * /}
                                                        
                                                        <h5>{item.title}</h5>
                                                        <h6>{item.designation}</h6>
                                                    </h1>
													<p className="m-b1"></p>
												</div>
                                            </div>

                                            
										</div>
									))}
                                </div>
                            </div> */}
                        </div>
        
                                </div>
                            </div>
                        </div>
						{ /* Counetr section */ }
							<SectionCounter />
						{ /* Counetr section End*/ }
							<VisionBlog />
							
                        {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
							{/*<FormStyle /> */}
							 <hr/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}


function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">Mission And Vision<span className="bg-primary"></span></h2>
						<p> </p>
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mission<span className="bg-primary"></span></h3>
								<p className="font-16">Expert Guidance to Motivate Students towards greater hopes and aspirations to fulfill their dreams in the field of Global Education and Employment.</p>
								<p className="font-16">We are striving to closely work with our clients to build trust and bring best solutions to their Study Abroad objectives with adherence to high professional standards and confidentiality.
									We aim to widen the scope of our services by offering an integrated approach to consulting and creating new opportunities for People who want to Study in their dream country.</p>
							</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Vision<span className="bg-primary"></span></h3>
								<p className="font-16">
									Our vision is to build a global platform in the education and employment with change international trends.</p>
								<p className="font-16">We are committed to provide the most efficient, innovative and effective immigration service.</p>
								<p><br/><br/><br/><br/></p>
							</div>
							</div>

						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div></div>
					</div>
				</div>

		</>
	)
}	
export {VisionBlog};
export default Aboutus1;
import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class StudyAbroadCounselling extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Study Abroad Counselling' activeMenu='Study Abroad Counselling' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">

                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Expert Career Counselling</Link></h4>
                                            </div>
                                            <p className="dlab-post-meta text-justify">
                                                Our trained and experienced team of counselors helps students identify a course and university that perfectly match their aptitude, interests, academic and financial background.
                                            </p>
                                            <p className="dlab-post-meta text-justify">
                                                We at Dreamz' Academy, view career not merely from a livelihood perspective but as a way of growing in both intellect and stature hence offering wise and realistic career counseling to students who approach us. Our expert career counselors with years of experience will guide you to choose a course and university that does justice to your talent and potential. We always strive to offer the best course and country for you, keeping in mind the changing world, job market, personal aspiration, aptitude, financial condition, etc. We only serve the best universities and colleges after reviewing their recognitions from time to time to make sure you have a worry-free education phase abroad.
                                            We have created many a winners out of ordinary folks you can be the next. want to enroll for result oriented IELTS PTE TOEFL Training? Get in touch
                                            </p>

                                        </div>


                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default StudyAbroadCounselling;
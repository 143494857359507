import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class StudyInUSA extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Study in Australia' activeMenu='Study in Australia' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>United States of America</Link></h4>
                                            </div>
                                            <div className="et_pb_text_inner">

                                                <p className="text-justify">The land of opportunities, the United States of America is the most popular education destination for international students across the globe. The USA has the largest international student population in the world, with nearly 5% of all the students in higher education institutions being international students. The reasons for most of the international students choosing the US for higher education are many. The university system of the United States is one of the finest in the world, with outstanding study programs at undergraduate and graduate levels. The US university system also gives vast research opportunities for students, another factor that makes the US education attractive for international students.</p>
                                                <h4>Variety of choices </h4>
                                                <p className="text-justify">The US higher education system gives a variety of choices for international students with a number of reputed higher education institutions and study programs. While some colleges and universities offer practical and employment-related courses, some others provide study programs related to arts, social sciences, and technology.</p>
                                                <h4>Opportunities for teaching and research</h4>
                                                <p className="text-justify">The US higher education gives great opportunities for teaching and research for students. At the graduate level, students often get an opportunity to work as research assistants or teaching assistants. Students often find this experience useful in their future career.</p>
                                                <h4>Career opportunities</h4>
                                                <p className="text-justify">Employers have high regard for candidates with an international degree as it indicates adaptability and international experience. The US companies which have operations in other countries prefer to recruit international graduates who are multilingual and are able to negotiate and interact with stakeholders in these countries.</p>
                                                <h3>Types of higher education institutions in the US</h3>
                                                <h4>Universities</h4>
                                                <p className="text-justify">Universities offer a range of study programs at undergraduate, graduate and doctoral levels. US universities are of two types–public and private. Public universities are funded by the US government and fees is lesser compared to private universities. At the same time, private universities are run with tuition fees, research outcome, donations and other sources of funding.</p>
                                                <h4>Community colleges</h4>
                                                <p className="text-justify">The community-based colleges offer vocational and certificate programs, usually with a duration of two years. Community colleges often work in collaboration with employers and offer several pathway programs for students.</p>
                                                <h4>Liberal arts colleges</h4>
                                                <p className="text-justify">Liberal arts colleges offer undergraduate degrees in liberal arts and sciences. These colleges can be both public and private. The duration of a liberal arts degree course is usually four years.</p>
                                                <p>At ImmiLaw Global we have some professional <strong>USA study abroad consultants</strong>&nbsp;who can guide you throughout the application and visa processes.</p>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default StudyInUSA;
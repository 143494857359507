import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Map from '../../Components/Map/Map';

class Footer4 extends Component {
    render() {
        return (
            <>
                <footer className="site-footer footer-white" >
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                

                                <div className="col-lg-5 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_getintuch">
                                        <h5 className="m-b30 text-white ">Contact us</h5>
                                        <ul>
                                            <li><i className="ti-location-pin"></i><strong>address</strong>
                                                <strong>Jalandhar:</strong>  #25, 26 Bansal Tower, 1st Floor above Subway, Pathankot Chowk, <br></br>+91 79017 11757</li>
                                                 

                                            <li><i className="ti-location-pin"></i>
                                            <strong>Kapurthala:</strong>   #1, Aman Nagar, Ground Floor, Abhay Tower, <br></br>+91 82830 17817</li>
                                            
                                            
                                            <li><i className="ti-location-pin"></i>
                                            <strong>Una:</strong>   Nangal Road, opp. Govt Girls College, Una, <br></br>+91 80913-13299</li>
                                            
                                            

                                            {/* <li><i className="ti-mobile"></i><strong>phone</strong>+91 79017 11757, +91 70098 30436<br/>+91 82830 17817, +91 80913-13299</li> */}
                                            <li><i className="ti-email"></i><strong>email</strong>info@dreamz.academy</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-6 col-sm-6 footer-col-4 ">
                                    <div className="widget">
                                       
                                        <div className="subscribe-form m-b20">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27249.174258828785!2d75.391201!3d31.382516!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a4906d3bb0ecf%3A0xaf1c5f074588e34c!2z8J2Qg_CdkKvwnZCe8J2QmvCdkKbwnZCzIPCdkIDwnZCc8J2QmvCdkJ3wnZCe8J2QpvCdkLI!5e0!3m2!1sen!2sus!4v1688140043073!5m2!1sen!2sus"
                                             width="800" height="450" style={{border:0}} > </iframe>
                                            
                                        </div>
                                        <ul className="list-inline m-a0">
                                            <li><Link to={"https://www.facebook.com/dreamzacademykpt/"} className="site-button facebook circle mr-1"><i className="fa fa-facebook"></i></Link></li>
                                            <li><Link to={"#"} className="site-button google-plus circle mr-1"><i className="fa fa-google-plus"></i></Link></li>
                                            <li><Link to={"#"} className="site-button linkedin circle mr-1"><i className="fa fa-linkedin"></i></Link></li>
                                            <li><Link href={"https://www.instagram.com/dreamzacademykpt/"} className="site-button instagram circle mr-1"><i className="fa fa-instagram"></i></Link></li>
                                            <li><Link to={"#"} className="site-button twitter circle mr-1"><i className="fa fa-twitter"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom bg-primary">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-left "> <span>Copyright © 2017-2022 Dreamz' Academy</span> </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-right ">
                                    <div className="widget-link ">
                                        <ul>
                                            <li> Website by </li>
                                            <li><a href="//appable.in"> Appable</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
				
            </>
        );
    }
}

export default Footer4;
import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class StudyInCanada extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Study in Canada' activeMenu='Study in Canada' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Canada</Link></h4>
                                            </div>
                                            <div className="et_pb_text_inner">
                                                <p>Selecting, a university/college and a course are the daunting parts
                                                    of overseas education. But worry not, following your needs and
                                                    preferences, our educational counsellors help you find the right
                                                    program in the right school in Canada. We can help you throughout
                                                    the application process to get you set up at the school of your
                                                    choice. Our service doesn’t end there; once you graduate from the
                                                    school with flying colours, we are happy to assist your transition
                                                    to permanent residence in Canada.</p>
                                                <h4>Why should you study in Canada?</h4>
                                                <p>Out of many famous Study Abroad destinations, Canada tops the list,
                                                    undoubtedly preferred by prospective International students. Be it
                                                    their world-class education, highly renowned degrees/ diplomas,
                                                    affordable tuition fees, employment opportunities, or scholarships;
                                                    there are many pretty apparent reasons why it becomes the ideal
                                                    choice of study abroad aspirants. Also, it’s a great country to
                                                    spend a lifetime as it offers a safe, economically stable
                                                    environment and better settlement solution for the residents.</p>
                                                <p>If you are a professional who wishes to migrate to Canada through
                                                    permanent residence Express entry programs are for you. As per the
                                                    stats, the number of International students studying at the Canadian
                                                    institutions has escalated to nearly 11% in 2017. Not Just the
                                                    education system the job prospects are also very high. Have a look
                                                    through these facts why Canada remains the superior choice among
                                                    overseas aspirants.</p>
                                                <ul>
                                                    <li>Certainly an immigration friendly country</li>
                                                    <li>Education standard par excellence</li>
                                                    <li>Immense scholarship opportunities</li>
                                                    <li>Best education at minimal fees</li>
                                                    <li>Work part-time while you study</li>
                                                    <li>An economically stable country with a workforce shortage</li>
                                                    <li>Higher Employment opportunities with high salaries</li>
                                                    <li>Nomination for Immigration to Canada under Provincial Nominee
                                                        Programs
                                                    </li>
                                                    <li>Postgraduate work permit on successful competition of studies
                                                    </li>
                                                    <li>Extension of work permit while your permanent resident
                                                        application is in process
                                                    </li>
                                                </ul>
                                                <h4>Level of Education</h4>
                                                    <p>Choosing the right program in a reputed university is one among the
                                                    first and foremost task while opting for study abroad program in
                                                    Canada. However, there are some other important factors to consider
                                                    and filter while selecting the course. By narrowing down the
                                                    interests to a specific program at one of Canada’s many designated
                                                    learning institutions, our p rofessional team of education
                                                    counsellors can help you with a list of possible fields of study.
                                                    All that you need to do is to pick a course from our customized
                                                    filtered list most suitable for you. </p>
                                                <h4>Universities and Colleges</h4>
                                                <p>As per the recent list, the country has a whopping number of 96
                                                    prestigious universities. To be more specific, the education system
                                                    in Canada encompasses both public and private schools which include
                                                    primary and secondary schools, post-secondary institutions like
                                                    universities, colleges, and vocational schools, private career
                                                    colleges. The education system in Canada is distinguished by the
                                                    number of years of study to achieve a specific qualification. Listed
                                                    below are the type of programs available in Canadian
                                                    universities.</p>
                                                <ul>
                                                    <li>Certificate level programs – less than one year or one to two
                                                        full-time semesters
                                                    </li>
                                                    <li>College or university diploma- two years maximum (four to six
                                                        full-time semesters)
                                                    </li>
                                                    <li>Associate degree (generally two years, four full-time
                                                        semesters)
                                                    </li>
                                                    <li>Bachelor’s degree programs (four / five years or eight full-time
                                                        semesters)
                                                    </li>
                                                    <li>Post Graduate Diploma / Certificate programs, for one or two
                                                        years
                                                    </li>
                                                    <li>Master’s degree programs</li>
                                                    <li>Doctorate or PhD programs (generally require four to six
                                                        years)
                                                    </li>
                                                </ul>
                                                 <h4>Dreamz' Academy offer an all-inclusive package</h4>
                                                <p>Right from the start, from choosing the right educational institution
                                                    until you get permanent residence in Canada, the expert staffs at
                                                    Dreamz' Academy will be there with you in each phase providing assistance
                                                    and support. Being an international organization that has been in
                                                    the practice of immigration and study abroad consultancy for years,
                                                    Dreamz' Academy thoroughly knows what is best for you!</p>
                                                <h4>Benefits of studying in Canada</h4>
                                                <p>The strongest reason for considering educating in Canada is the
                                                    opportunity to work part-time with the academics. International
                                                    students are authorized to work 20 hours per week while school is in
                                                    session and 40 hours per week during vacation time. The country also
                                                    offers post-graduate work permit up to three years depending on the
                                                    duration of the study period.</p>
                                                <h4>Work Permit </h4>
                                                <p>Work Permit: Full- time students who hold a valid study permit and
                                                    social insurance number (SIN), is eligible to work while they study.
                                                    Students can work full time (40 hours) during vacation time like
                                                    winter and summer holidays and can work up to 20 hours during
                                                    regular college sessions.</p>
                                                 <h4>Work Permit as Intern</h4>
                                                <p>Interestingly, some programs include work experience or internship as
                                                    part of the curriculum; however, the student needs a co-op or intern
                                                    work permit. The concerned government department will offer it if
                                                    he/ she has a letter from the school that confirms all students in
                                                    the program need to perform work placement to get their degree.</p>
                                                <h4>Spousal Work Permit</h4>
                                                <p>The spouse of an international student is entitled to a have an open
                                                    work permit, which does not require a job offer or Labour Market
                                                    Impact Assessment from Canada. By securing an open work permit, the
                                                    spouse of an international student can live and work full time in
                                                    Canada.</p>
                                                <h4>How do I settle in Canada permanently after my
                                                    studies?</h4>
                                                <p>Stay back and permanent residence: Right after the successful
                                                    completion of the academic course, it is crucial to secure a job
                                                    during the stay back tenure as it is the first step in the
                                                    eligibility criteria for applying for permanent residence under
                                                    Canadian Experience Class. International students enrolled only in a
                                                    Designated Learning Institution is eligible for PGWP
                                                    (Post-Graduation Work Permit Program), which allows the applicant to
                                                    legally work in Canada.</p>
                                                 <h4>Student Life</h4>
                                                <p>Canada is widely recognized as one of the safest places in the world
                                                    to live. Being a student in the country help will experience a new
                                                    and diversified culture in a friendly ambience. However, as an
                                                    international student, you should have a proper planning and
                                                    financial support before you reach there.</p>
                                                <p>Part-time jobs (Work on campus and work off campus) will help the
                                                    students to meet their financial needs during their study. Besides,
                                                    if you are an adrenaline junkie, Canada is an ideal option as the
                                                    country offers lots of destinations to explore and have some
                                                    adventurous pursuits.</p>
                                                <p>Also, when it comes to accommodation, most of the universities
                                                    provide on-campus housing, which may not be an affordable option,
                                                    but a more convenient one. Besides, healthcare is a bit expensive in
                                                    Canada, and hence it is vital that the students should secure
                                                    Medical insurance while they reach there.</p>
                                                <h3>How can Dreamz' Academy help you apply for a Study Visa?
                                                </h3>
                                                <p>We select only the best and reputed school by following your interest
                                                    and area of study.<br />
                                                        Following the complete assessment, will check your eligibility
                                                        against the admission criteria of the preferred institute.<br/>
                                                        Obtaining the admission letter from the academic
                                                        institution.<br />
                                                        Provides all assistance in getting education loan and other
                                                        financial certificates.</p>
                                                <ul>
                                                    <li>Apply for Visa on behalf of the applicant</li>
                                                    <li>Provide pre-departure counselling and post-arrival services</li>
                                                </ul>
                                                <p>If you wish to study in Canada, for any assistance visit or call Dreamz' Academy, a global study abroad consultants in Punjab.</p>
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default StudyInCanada;
import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class CourseAdvice extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Course Advice' activeMenu='Course Advice' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">

                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Let us help you pick the course for an enriching career and life</Link></h4>
                                            </div>

                                            <p className="dlab-post-meta text-justify">
                                                With a myriad of courses from scores of Universities and Colleges to choose from, zeroing in on the perfect one is not at all easy. With us there is no room for worry as we hand-hold you to make your perfect pick based on our current qualification, aptitude, academic learning, budget constraints and most vitally prevailing trends to ensure you just don’t complete any course but one which will offer you an enriching career. Our expert counselors will help you to shortlist courses, offering you multiple options across institutions in your preferred study destination and help you finalize the most suited course from shortlisted ones.
                                            </p>

                                        </div>


                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default CourseAdvice;
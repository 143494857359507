import React, {Component,useState,useEffect,Fragment } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import Header from '../../Layout/header3';
import Footer from '../../Layout/footer4';
import PageTitle from './../../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import Counter from './../../Element/Counter';

//images
//import bnr1 from './../../../images/banner/bnr1.jpg';
import box1 from './../../../images/dreamz/pic2.jpg';
import box2 from './../../../images/dreamz/pic3.jpg';
import box3 from './../../../images/dreamz/pic4.jpg';
import box4 from './../../../images/dreamz/pic5.jpg';
import box5 from './../../../images/dreamz/pic6.jpg';
import box6 from './../../../images/dreamz/pic7.jpg';
import box7 from './../../../images/dreamz/pic8.jpg';
import box8 from './../../../images/dreamz/pic9.jpg';
import box9 from './../../../images/dreamz/pic10.jpg';
import box10 from './../../../images/dreamz/pic11.jpg';
import box11 from './../../../images/dreamz/pic12.jpg';
import box12 from './../../../images/dreamz/pic13.jpg';
import box13 from './../../../images/dreamz/pic14.jpg';
import box14 from './../../../images/dreamz/pic15.jpg';
import box15 from './../../../images/dreamz/pic16.jpg';
import box16 from './../../../images/dreamz/pic17.jpg';
import box17 from './../../../images/dreamz/pic18.jpg';
import box18 from './../../../images/dreamz/pic19.jpg';
import box19 from './../../../images/dreamz/pic20.jpg';
import box20 from './../../../images/dreamz/pic21.jpg';
import box21 from './../../../images/dreamz/pic22.jpg';
import box22 from './../../../images/dreamz/pic23.jpg';
import box23 from './../../../images/dreamz/pic24.jpg';
import box24 from './../../../images/dreamz/pic25.jpg';
import box25 from './../../../images/dreamz/pic26.jpg';

import box40 from './../../../images/dreamz/pic40.jpg';
import box41 from './../../../images/dreamz/pic41.jpg';
import box42 from './../../../images/dreamz/pic42.jpg';
import box43 from './../../../images/dreamz/pic43.jpg';
import box44 from './../../../images/dreamz/pic44.jpg';
import box45 from './../../../images/dreamz/pic45.jpg';
import box46 from './../../../images/dreamz/pic46.jpg';
import box47 from './../../../images/dreamz/pic47.jpg';
import box48 from './../../../images/dreamz/pic48.jpg';
import box49 from './../../../images/dreamz/pic49.jpg';
import box50 from './../../../images/dreamz/pic50.jpg';
import box51 from './../../../images/dreamz/pic51.jpg';
import box52 from './../../../images/dreamz/pic52.jpg';
import box53 from './../../../images/dreamz/pic53.jpg';
import box54 from './../../../images/dreamz/pic54.jpg';
import box55 from './../../../images/dreamz/pic55.jpg';
import box56 from './../../../images/dreamz/pic56.jpg';
import box57 from './../../../images/dreamz/pic57.jpg';
import box58 from './../../../images/dreamz/pic58.jpg';
import box59 from './../../../images/dreamz/pic59.jpeg';
import box60 from './../../../images/dreamz/pic60.jpeg';
import box61 from './../../../images/dreamz/pic61.jpeg';
import box62 from './../../../images/dreamz/pic62.jpeg';
import box63 from './../../../images/dreamz/pic63.jpeg';

const imageBlog = [
	{ Large: box59 , tag: ['IELTS'] },
	{ Large: box1 , tag: ['Visa Approved'] },
	{ Large: box42 , tag: ['PTE'] }, 
	{ Large: box2 , tag: ['Visa Approved'] }, 
	{ Large: box3 , tag: ['Visa Approved'] }, 
	{ Large: box60 , tag: ['IELTS'] },
	{ Large: box4 , tag: ['Visa Approved'] },
	{ Large: box5 , tag: ['Visa Approved'] }, 
	{ Large: box6 , tag: ['Visa Approved'] }, 
	{ Large: box61 , tag: ['IELTS'] },
	
	{ Large: box43 , tag: ['PTE'] },
	{ Large: box44 , tag: ['PTE'] }, 
	{ Large: box9 , tag: ['Visa Approved'] }, 
	{ Large: box11 , tag: ['Visa Approved'] }, 
	{ Large: box62 , tag: ['IELTS'] },
	{ Large: box10 , tag: ['Visa Approved'] }, 
	{ Large: box12 , tag: ['Visa Approved'] }, 
	{ Large: box40 , tag: ['PTE'] },
	{ Large: box41 , tag: ['PTE'] }, 
	{ Large: box13 , tag: ['Visa Approved'] },
	{ Large: box14 , tag: ['Visa Approved'] },
	{ Large: box63 , tag: ['IELTS'] },
	{ Large: box15 , tag: ['Visa Approved'] },
	
	{ Large: box18 , tag: ['Visa Approved'] },
	{ Large: box47 , tag: ['PTE'] },
	{ Large: box19 , tag: ['Visa Approved'] },
	{ Large: box20 , tag: ['Visa Approved'] },
	{ Large: box21 , tag: ['Visa Approved'] },
	{ Large: box48 , tag: ['PTE'] },
	{ Large: box49 , tag: ['PTE'] },
	{ Large: box7 , tag: ['Visa Approved'] }, 
	{ Large: box8 , tag: ['Visa Approved'] }, 
	{ Large: box50 , tag: ['PTE'] },
	{ Large: box51 , tag: ['PTE'] },
	{ Large: box52 , tag: ['PTE']},
	{ Large: box22 , tag: ['Visa Approved'] },
	
	{ Large: box25 , tag: ['Visa Approved'] },
	
	{ Large: box45 , tag: ['PTE'] },
	{ Large: box46 , tag: ['PTE'] },
	{ Large: box16 , tag: ['Visa Approved'] },
	{ Large: box17 , tag: ['Visa Approved'] },
	
	{ Large: box53 , tag: ['PTE'] },
	{ Large: box54 , tag: ['PTE'] },
	{ Large: box55 , tag: ['PTE'] },
	{ Large: box56 , tag: ['PTE'] },
	{ Large: box23 , tag: ['Visa Approved'] },
	{ Large: box24 , tag: ['Visa Approved'] },
	{ Large: box57 , tag: ['PTE'] },
	{ Large: box58 , tag: ['PTE'] },
	
	
	
	
	
	

	
]
//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link portfolio-fullscreen" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

const TagLi = ({name, handlesettag, tagActive}) => {                                                               
	return ( 
			
		<li className={` tag ${tagActive ? 'btn active' : 'btn'}` } onClick={() => handlesettag(name)} >
			<input type="radio" />
			<Link to={"#"} className="site-button-secondry radius-sm" ><span>{name} {''}</span> </Link>
		</li> 
	);
};


class ProtfolioFullWidth extends Component{
	render(){
		return(
			<Fragment>	
			<Header />
			<div className="page-content bg-white">
				{/*  banner  */}
				<div className="bg-black"  >
                        <PageTitle motherMenu='Latest Result' activeMenu='Latest Result' style={{ padding:"5px" }} />
                    </div>
				{/*  Section-1 Start  */}
				<PortfolioItem />
				
			</div>
			<Footer/>
			</Fragment>			
		)
	}
} 
function PortfolioItem(){
	const [tag, setTag] = useState('All');
	const [filteredImages, setFilterdImages] = useState([]);
	
	useEffect( () => {
		tag === 'All' ? setFilterdImages(imageBlog) : setFilterdImages(imageBlog.filter( image => image.tag.find(element => element === tag)))
	}, [tag])	
	
	return(
		<>
			<div className="section-full content-inner-1 portfolio text-uppercase">		
				<div className="site-filters clearfix center  m-b40">
					<ul className="filters" data-toggle="buttons">
						<TagLi name="All"  handlesettag={setTag} tagActive={ tag === 'All' ? true : false }	/>
						<TagLi name="IELTS"  handlesettag={setTag} tagActive={ tag === 'IELTS' ? true : false }	/>
						<TagLi name="PTE" handlesettag={setTag} tagActive={ tag === 'PTE' ? true : false }	 />
						<TagLi name="Visa Approved"  handlesettag={setTag} tagActive={ tag === 'Visa Approved' ? true : false }	/>
						
					</ul>
				</div>
				<SimpleReactLightbox>
					<SRLWrapper >
						<div className="clearfix">
							<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1">
								<Masonry
									className={'my-gallery-class'} // default ''
									options={masonryOptions} // default {}
									disableImagesLoaded={false} // default false
									updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
									imagesLoadedOptions={imagesLoadedOptions} // default {}
								>
									{filteredImages.map((item, index)=>(
										<li className="web design card-container col-lg-3 col-md-6 col-xs-12 col-sm-6 p-a0" key={index}>
											<div className="dlab-box dlab-gallery-box">
												<div className="dlab-media dlab-img-overlay1 dlab-img-effect"> 
													<img src={item.Large} alt="" />
													<div className="overlay-bx">
														<div className="overlay-icon align-b text-white text-left">
															<div className="text-white text-left port-box">
																
																<Iconimage />
															</div>
														</div>
													</div>
												</div>
											</div>
										</li>
									))}	
								</Masonry>	
							</ul>
						</div>
					</SRLWrapper>
				</SimpleReactLightbox>	
				<Counter/>
			</div>
		</>
	);
}
export {box1, box2, box3, box4, box5, box6, box7, box8, box9, box10, box11, box12, box13, box14, box15, box16, box17, box18, box19, box20, box21, box22, box23, box24, box25, imageBlog};
export default ProtfolioFullWidth;

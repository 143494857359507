import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';

import TabStyle2 from './ShortCode/TabStyle2';

import Counter from './../Element/Counter';
import HomeOwlSlider2 from './../Element/HomeOwlSlider2';


//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
//import {Link} from "react-router-dom";

class VisaServices extends Component {
    render() {
        return (

            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Educational Services' activeMenu='Educational Services' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                
                                            </div>
                                            <div className="et_pb_text_inner">
                                                {/*<p>IELTS- International English Language Testing System is designed to help you acquire the necessary skills to study, work or live abroad. The test is conducted on Academic or general Training standards and comprises 4 key measures- Listening, Reading, Writing, and Speaking.</p>
                                                
                                                <p>Dream’z Academy is home to specialized learning resources curated by seasoned trainers with years of experience and expertise. Our teaching methods are detail-oriented, in sync with the latest guidelines, and focused on deep rooted issues in language. </p>
                                                
        Call us today to achieve your dream IELTS score!*/}
                                                <TabStyle2></TabStyle2>
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Our Services --> */}
                <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Your Dream Destination</h2>
                                    <h5 className="box-title m-tb0">The dream destination of progress & development will be achieved by imparting latest education to the youth<span className="bg-primary"></span></h5>


                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div>
                        
                <div className="choses-info text-black" >
                                <Counter />
                                
                            </div>
                <Footer />
            </>
        )
    }
}
export default VisaServices;
import React,{Component} from 'react';
import {Link} from 'react-router-dom';
//import SidebarCarousel from './SidebarCarousel';
//import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox';

//Images
// import pic1 from './../../images/blog/recent-blog/pic1.jpg';
// import pic2 from './../../images/blog/recent-blog/pic2.jpg';
// import pic3 from './../../images/blog/recent-blog/pic3.jpg';
// import gallery1 from './../../images/gallery/pic1.jpg';
// import gallery2 from './../../images/gallery/pic2.jpg';
// import gallery3 from './../../images/gallery/pic3.jpg';
// import gallery4 from './../../images/gallery/pic4.jpg';
// import gallery5 from './../../images/gallery/pic5.jpg';
// import gallery7 from './../../images/gallery/pic7.jpg';
// import gallery8 from './../../images/gallery/pic8.jpg';
// import gallery9 from './../../images/gallery/pic9.jpg';

/* const widgetPost =  [
	{image: pic1, },{image: pic2, },{image: pic3, },
]; */
/* const postThum = [
	{images: gallery1}, {images: gallery2}, {images: gallery3 }, {images: gallery4}, 
	{images: gallery5}, {images: gallery7}, {images: gallery8}, {images: gallery9}, 
]; */


class SidebarServicesVisaServices extends Component{
	render(){
		return(
			<>
				<aside className="side-bar">

					<div className="widget recent-posts-entry">
						<h5 className="widget-title style-1">Visa Services</h5>
						<div className="widget-post-bx">
							<div className="widget-post clearfix"  >
								<div  >
									<ul>
                                        <li>::<Link to={'study-visa'} className="dez-page">Study Visa</Link></li>
                                        <li>::<Link to={'tourist-visa'} className="dez-page">Tourist Visa</Link></li>
                                        <li>::<Link to={'super-visa'} className="dez-page">Super Visa</Link></li>
                                        <li>::<Link to={'work-visa'} className="dez-page">Work Visa</Link></li>
                                        <li>::<Link to={'pr'} className="dez-page">PR (Permanent Residency)</Link></li>
                                        <li>::<Link to={'spouse-visa'} className="dez-page">Spouse Visa</Link></li>
                                    </ul>
								</div>
							</div>

						</div>
					</div>
					<div className="widget recent-posts-entry">
						<h5 className="widget-title style-1">Values we cherish</h5>
						<div className="widget-post-bx">
							<div className="widget-post clearfix"  >
								<div  >
									<ul>
										<li>:: Transparency</li>
										<li>:: Reliability</li>
										<li>:: Accountability</li>
										<li>:: Integrity</li>
										<li>:: Passion</li>
										<li>:: Teamwork</li></ul>
								</div>
							</div>
						</div>
					</div>
				</aside>
			</>
		)
	}
}
export default SidebarServicesVisaServices;
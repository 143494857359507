import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'./'}><span className="ti-home"></span> </Link>
					</li>
					<li><Link to={'#'}>About Us <i className="fa fa-chevron-down "></i></Link>
						<ul className="sub-menu">
							<li><Link to={'company-profile'} className="dez-page">Company Profile</Link></li>
							<li><Link to={'our-team'} className="dez-page">Our Team</Link></li>
							 
							<li><Link to={'why-dreamz-academy'} className="dez-page">Why Dreamz' Academy</Link></li>
	
							{/*<li><Link to={'#'} className="dez-page">Core Values</Link></li>*/}

						</ul>
					</li>
				{/*	<li><Link to={'#'}>Study Abroad <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'study-in-canada'} className="dez-page"><img style={{ width:"15px" }}
								src="http://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg"/> Canada</Link></li>
							<li><Link to={'study-in-australia'} className="dez-page"><img style={{ width:"15px" }}
								src="http://purecatamphetamine.github.io/country-flag-icons/3x2/AU.svg"/> Australia</Link></li>
							<li><Link to={'study-in-uk'} className="dez-page"><img style={{ width:"15px" }}
								src="http://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg"/> UK</Link></li>
							<li><Link to={'study-in-new-zealand'} className="dez-page"><img style={{ width:"15px" }}
								src="http://purecatamphetamine.github.io/country-flag-icons/3x2/NZ.svg"/> New Zealand</Link></li>
							<li><Link to={'study-in-usa'} className="dez-page"><img style={{ width:"15px" }}
								src="http://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg"/> USA</Link></li>
						</ul>
					</li>
*/}
	<li><Link to={'#'}>Education Services <i className="fa fa-chevron-down"></i></Link>
	<ul className="sub-menu">
							<li><Link to={'ielts'} className="dez-page">IELTS</Link></li>
							<li><Link to={'cd-ielts'} className="dez-page">CD-IELTS</Link></li>
							<li><Link to={'pte'} className="dez-page">PTE</Link></li>
							<li><Link to={'toefl'} className="dez-page">TOEFL</Link></li>
							<li><Link to={'spoken-english'} className="dez-page">Spoken English</Link></li>
							<li><Link to={'online-classes'} className="dez-page">Online Classes</Link></li>
							<li><Link to={'life-skill'} className="dez-page">Life Skills</Link></li>
							<li><Link to={'language-classes'} className="dez-page">Language Classes</Link></li>
							<li><Link to={'duolingo-english-test'} className="dez-page">Duolingo English Test</Link></li>
							
							<li><Link to={'interview-preperation'} className="dez-page">Interview Preperation</Link></li>
						</ul>
						
						
	</li>
				{/*}	<li><Link to={'#'}>Our Services <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'test-preparation-training'} className="dez-page">Test Preparation Training</Link></li>
							<li><Link to={'study-abroad-counselling'} className="dez-page">Study Abroad Counselling</Link></li>
							<li><Link to={'course-advice'} className="dez-page">Course Advice</Link></li>
							<li><Link to={'university-college-application-processing'} className="dez-page">University/College Application Processing</Link></li>
						</ul>
					</li>
					*/}
					<li><Link to={'#'}>Visa Services <i className="fa fa-chevron-down" /></Link>
						<ul className="sub-menu">
							<li><Link to={'study-visa'} className="dez-page">Study Visa</Link></li>
							<li><Link to={'tourist-visa'} className="dez-page">Tourist Visa</Link></li>
							<li><Link to={'super-visa'} className="dez-page">Super Visa</Link></li>
							<li><Link to={'work-visa'} className="dez-page">Work Visa</Link></li>
							<li><Link to={'pr'} className="dez-page">PR (Permanent Residency)</Link></li>
							<li><Link to={'spouse-visa'} className="dez-page">Spouse Visa</Link></li>
						</ul>
						
					</li>
					<li  ><Link to={'latest-result'}>Latest Result </Link>
					</li>
					<li><Link to={'news-and-events'} className="dez-page">News & Events</Link></li>
					<li><Link to={'contact'} className="dez-page">Contact Us</Link></li>


				</ul>
		</>
		)
	}
}
export default HeaderMenu;
import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class UniversityCollegeApplicationProcessing extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='University College Application Processing' activeMenu='University College Application Processing' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">

                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Choose Appropriate Program</Link></h4>
                                            </div>
                                            <p className="dlab-post-meta text-justify">
                                                We offer guidance throughout the entire admission process to ensure a complete, error-free application for the student backed by consistent follow-ups with Universities to ensure expedited admissions.
                                            </p>
                                            <p className="dlab-post-meta text-justify">
                                                When it comes to admission support, there is nothing that compares to Dreamz' Academy. Owing to our strong association with reputed Overseas Program Providers, associates, and support offices across the world, we can seamlessly facilitate your admission to over 200 universities, colleges, and polytechnics across the world as per your aptitude and preferences. We are equipped to assist students for admissions with any Overseas Program Providers across the world, irrespective of our association with them. Our experienced team of advisors renders one-to-one counseling so that you get to choose the appropriate program, institution, and country taking into consideration your ambition, academic and financial background. Our internationally trained admission executives assist you in error-free application formalities and if required, groom you for the online/telephonic interview. We at Dreamz' Academy guarantee a hassle-free admission process to our students, which ensures a high success rate, to top it, all our comprehensive services for students come absolutely free of charge.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default UniversityCollegeApplicationProcessing;
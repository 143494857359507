import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import SectionCounter from './../Element/SectionCounter';
//import TestimonialCarousel from './../Element/TestimonialCarousel';
//import FormStyle from './../Element/FormStyle';

//Images
import  bnr1 from './../../images/banner/bnr5.jpg';
import  php from './../../images/expertise/php.png';
import  joomla from './../../images/expertise/joomla.png';
import  drupal from './../../images/expertise/drupal.png';
import  laravel from './../../images/expertise/laravel.png';
import  magento from './../../images/expertise/magento.png';
import  wordpress from './../../images/expertise/wordpress.png';
import  oscommerce from './../../images/expertise/oscommerce.png';
import  ecommerce from './../../images/expertise/ecommerce.png';
import  zend from './../../images/expertise/zend-framework.png';
import  ruby from './../../images/expertise/ruby-rails.png';
import  zencart from './../../images/expertise/zencart.png';
import  xcart from './../../images/expertise/xcart.png';
import  python from './../../images/expertise/python-framework.png';
//import bg1 from './../../images/background/bg1.jpg';
//import bgmap from './../../images/background/bg-map.jpg';

/* const imageBlog = [
	{image: php, title: 'Php', },
	{image: joomla, title: 'Joomla', },
	{image: drupal, title: 'Drupal', },
	{image: laravel, title: 'Laravel', },
	{image: magento, title: 'Magento 1.x', },
	{image: magento, title: 'Magento 2.x', },
	{image: wordpress, title: 'Wordpress', },
	{image: oscommerce, title: 'Oscommerce', },
	{image: ecommerce, title: 'ecommerce', },
	{image: zend, title: 'Zend Framework', },
	{image: ruby, title: 'Ruby Rails', },
	{image: zencart, title: 'Zencart', },
	{image: xcart, title: 'Xcart', },
	{image: python, title: 'Python Framework', },
]; */

class Expertise extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Expertise' activeMenu='Expertise' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7">
                                        <div className="section-head text-black ">
                                            <h4 className="text-gray-dark font-weight-300 m-b10">&nbsp;</h4>
                                            <h2 className="box-title m-tb0">Why you should prefer consulting TCM Global?
                                                <span className="bg-primary"></span></h2>
                                            <p className="text-black text-justify">In the present era, most of the youth want to attain knowledge from the best universities present in Australia but somehow they are facing some hindrances in converting their dreams into reality. They are searching for the one who can provide an opportunity to chase the dreams and touch the limitless sky. So, we are that one source that will solve all your queries that are becoming a hurdle between your dreams. As we have experienced professionals who knows all the minor things about studying on an international platform. Accordingly, our main motive is to provide reliable guidance to all the students who are looking for an opportunity to enlighten their career on international background. </p>
                                        </div>
                                        <div className="section-head text-black ">
                                            <h4 className="text-gray-dark font-weight-300 m-b10">&nbsp;</h4>
                                            <h2 className="box-title m-tb0">Services under Single Roof
                                                <span className="bg-primary"></span></h2>
                                            <p className="text-black text-justify"> We are focusing on offering high-quality services to our clients. We help them to meet and exceed challenges as active participants informing the future of our world.<br/>
                                                We are an established Australian migration constancy that handles fields like Student Visas, General Skilled Migration Visas, Employer-Sponsored Visas, Parent Visas, Visitor Visas, and Other Types of Family Visas.</p>
                                        </div>

                                    </div>
                                    <div className="col-lg-4 col-md-5 sticky-top">
                                        <form className="inquiry-form inner dzForm" action="script/contact.php">
                                            <div className="dzFormMsg"></div>
                                            <h3 className="box-title m-t0 m-b10">Need Help? <span className="bg-primary"></span></h3>
                                            <p></p>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                            <input name="dzName" type="text" required className="form-control" placeholder="First Name" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                            <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                            <input name="dzEmail" type="email" className="form-control" required placeholder="Your Email Id" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                            <textarea name="dzMessage" rows="4" className="form-control" required placeholder="Type your message here...."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                            <input className="form-control d-none" data-recaptcha="true" required data-error="Please complete the Captcha" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <button name="submit" type="submit" value="Submit" className="site-button button-lg"> <span>Send Message!</span> </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
                        {/* <!-- Why Chose Us --> */}
							<SectionCounter />
                        {/* <!-- Why Chose Us End --> */}
                        {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")",  backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 	">
								 <TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END -- >
                         <!-- Get in touch -- >
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                         <!-- Get in touch --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default Expertise;
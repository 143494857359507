import React, { Component } from 'react';
import Header from '../../Layout/header3';
import Footer from '../../Layout/footer4';
import PageTitle from '../../Layout/PageTitle';
import SidebarEducation from '../../Element/SidebarServicesEducationalServices';
import {Link} from 'react-router-dom';
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';


// import TabStyle2 from './../ShortCode/TabStyle1';

import Counter from '../../Element/Counter';
import HomeOwlSlider2 from '../../Element/HomeOwlSlider2';
// import TabStyle1 from './../ShortCode/TabStyle1';
import { TabPane } from 'reactstrap';


//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
//import {Link} from "react-router-dom";

class PasswordEnglishTest extends Component {
    render() {
        return (

            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Educational Services' activeMenu='Password English Test' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                
                                            </div>
                                            <div className="et_pb_text_inner">
                                            <TabPane tabId="1">
									<p className="m-b10">
                                    <b>PASSWORD ENGLISH TESTS</b> are international examinations for English panned across 120 countries. Since 2008, Institutions, Government bodies,and Companies have been relying on Password to evaluate English proficiency following the CEFR guidelines.(Common European Framework of Reference for Languages)

									</p>
									<p>
                                    Dream’z Academy is home to specialized learning resources curated by seasoned trainers with years of experience and expertise. Our teaching methods are detail-oriented, in sync with the latest guidelines, and focused on deep rooted issues in language. 

									</p>
                                    Call us today to achieve your dream score!
									<table className="table table-bordered" >
										<tr>
											<td>1-on-1 speaking Labs </td>
											<td>Weekly Exam-Like Mock Tests</td>
                                            <td>Grammar & Vocabulary Classes</td>
											
										</tr>
										 <tr>
											<td>Latest exam topics and study material</td>
											<td>Individual Attention to every student</td>
                                            <td>Extra-sessions for all modules revision</td>
											
										</tr>
										
									</table>

								</TabPane>
                                <br/>
                                <br/>
                                <div>
                                    <Link to ={"/contact"} className="site-button outline outline-2 button-lg">Contact us</Link>
                                
                                </div>
                                                {/* <TabStyle1></TabStyle1> */}
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <SidebarEducation />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Our Services --> */}
                <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Your Dream Destination</h2>
                                    <h5 className="box-title m-tb0">The dream destination of progress & development will be achieved by imparting latest education to the youth<span className="bg-primary"></span></h5>


                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div>
                        
                <div className="choses-info text-black" >
                                <Counter />
                                
                            </div>
                <Footer />
            </>
        )
    }
}
export default PasswordEnglishTest;
import React, { Component } from 'react';
import Header from '../../Layout/header3';
import Footer from '../../Layout/footer4';
import PageTitle from '../../Layout/PageTitle';
import SidebarEducation from '../../Element/SidebarServicesEducationalServices';
import {Link} from 'react-router-dom';
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';


// import TabStyle2 from './../ShortCode/TabStyle1';

import Counter from '../../Element/Counter';
import HomeOwlSlider2 from '../../Element/HomeOwlSlider2';
// import TabStyle1 from './../ShortCode/TabStyle1';
import { TabPane } from 'reactstrap';


//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
//import {Link} from "react-router-dom";

class PTE extends Component {
    render() {
        return (

            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Educational Services' activeMenu='PTE' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                
                                            </div>
                                            <div className="et_pb_text_inner">
                                            <TabPane tabId="1">
									<p className="m-b10">
                                    <b>PTE-Pearson Test of English</b> is an internationally accredited testing system designed to help abroad aspirants by taking the testing process online and thereby enabling for frequent tests with quicker results and less manual error. PTE also offers helpful guides to help aspirants in their preparation.
									</p>
									<p>
                                    Here at Dream’s we have leveraged the best technology to simulate test-like environments and have a reservoir of certified softwares and additional help aids to give the best learning experience to our students in our dedicated PTE lab. 
									</p>
									Talk to us now and get started on your PTE path!

									<table className="table table-bordered" >
										<tr>
											<td>Latest testing Softwares</td>
											<td>Online Strategy for best Scores</td>
											<td>Additional Writing sessions</td>
										</tr>
										<tr>
											<td>Fully-equipped Hi-Tech Labs</td>
											<td>Grammer & Vocabulary Classes</td>
											<td>Individual Attention to every student</td>
										</tr>
										
									</table>

								</TabPane>
                                <br/>
                                <br/>
                                <div>
                                    <Link to ={"/contact"} className="site-button outline outline-2 button-lg">Contact us</Link>
                                
                                </div>
                                                {/* <TabStyle1></TabStyle1> */}
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <SidebarEducation />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Our Services --> */}
                <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Your Dream Destination</h2>
                                    <h5 className="box-title m-tb0">The dream destination of progress & development will be achieved by imparting latest education to the youth<span className="bg-primary"></span></h5>


                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div>
                        
                <div className="choses-info text-black" >
                                <Counter />
                                
                            </div>
                <Footer />
            </>
        )
    }
}
export default PTE;
import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class TestPreparationTraining extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Test Preparation Training' activeMenu='Test Preparation Training' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">

                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Excellence Speaks Itself</Link></h4>
                                            </div>
                                            <p className="dlab-post-meta text-justify">
                                               Study Abroad/ Migration or job abroad, what ever be your reason, with Dreamz' Academy, One of the best training academy in the region, in terms of results you can prepare with confidence and focus to secure high band scores in IELTS/ PTE / TOEFL / Spoken English and other standardized tests. With state of the art infrastructure/ language lab coupled with highly experienced and certified trainers, latest study aids, personal attention and numerous mock exercises by your side a high band score is just a logical outcome. This is reflected in the testimonies of thousands of successful students who swear by our training and the stellar results that have secured.
                                            </p>
                                            <p className="dlab-post-meta text-justify">We have created many a winners out of ordinary folks you can be the next. want to enroll for result oriented IELTS PTE TOEFL Training? Get in touch
                                            </p>

                                        </div>


                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default TestPreparationTraining;
import React, { Component } from 'react';
import Header from '../../Layout/header3';
import Footer from '../../Layout/footer4';
import PageTitle from '../../Layout/PageTitle';
import SidebarVisa from '../../Element/SidebarServicesVisaServices';
import {Link} from 'react-router-dom';
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';


// import TabStyle2 from './../ShortCode/TabStyle1';

import Counter from '../../Element/Counter';
import HomeOwlSlider2 from '../../Element/HomeOwlSlider2';
// import TabStyle1 from './../ShortCode/TabStyle1';
import { TabPane } from 'reactstrap';


//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
//import {Link} from "react-router-dom";

class SuperVisa extends Component {
    render() {
        return (

            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Visa Services' activeMenu='Super Visa' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                
                                            </div>
                                            <div className="et_pb_text_inner">
                                            <TabPane tabId="1">
									<p className="m-b10">
									Are you a parent or grandparent of a permanent resident and want to live with them? The super visa is a multi-entry visa that provides multiple entries for a period up to 10 years allowing an individual to stay abroad for up to 5 years at a time.
									</p>
									<p>
                                    Our seasoned Visa experts & counsellors help you understand the visa process step-by-step and take care of the formalities so you don’t have to. We are proud of our transparent process and always keep you updated with fast results. 
									</p>
									Call us today to empower your overseas endeavours!

									<table className="table table-bordered" >
										<tr>
											<td>High Success Rate</td>
											<td>Transparent & Fast Process</td>
										</tr>
										<tr>
											<td>Handholding support in formalities</td>
											<td>Previously Refused cases accepted</td>
										</tr>
										
									</table>

								</TabPane>

                                <br/>
                                <br/>
                                <div>
                                    <Link to ={"/contact"} className="site-button outline outline-2 button-lg">Contact us</Link>
                                
                                </div>
                                                {/* <TabStyle1></TabStyle1> */}
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <SidebarVisa />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Our Services --> */}
                <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Your Dream Destination</h2>
                                    <h5 className="box-title m-tb0">The dream destination of progress & development will be achieved by imparting latest education to the youth<span className="bg-primary"></span></h5>


                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div>
                        
                <div className="choses-info text-black" >
                                <Counter />
                                
                            </div>
                <Footer />
            </>
        )
    }
}
export default SuperVisa;
import React, { Component } from 'react';
import Header from '../../Layout/header3';
import Footer from '../../Layout/footer4';
import PageTitle from '../../Layout/PageTitle';
import SidebarEducation from '../../Element/SidebarServicesEducationalServices';
import {Link} from 'react-router-dom';
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';


// import TabStyle2 from './../ShortCode/TabStyle1';

import Counter from '../../Element/Counter';
import HomeOwlSlider2 from '../../Element/HomeOwlSlider2';
// import TabStyle1 from './../ShortCode/TabStyle1';
import { TabPane } from 'reactstrap';


//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
//import {Link} from "react-router-dom";

class LanguageClasses extends Component {
    render() {
        return (

            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Educational Services' activeMenu='Language Classes' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                
                                            </div>
                                            <div className="et_pb_text_inner">
                                            <TabPane tabId="1">
									<p className="m-b10">
                                    Want to <b>learn new Languages</b>, but don’t want to attend boring classes with the same old checkboxes and rules? Let us change your perspective and help you enhance your linguistic skills and master various levels in fun & interesting ways. 

									</p>
									<p>
                                    Dream’z is home to experienced trainers skilled in universally sought languages. With dynamic lesson plans and 1-on-1 training sessions, we help you upskill yourself and get certified in all levels from beginners to experienced!
									</p>
                                    Master Beginner, Intermediate, & Advanced Levels of these Languages!
									<table className="table table-bordered" >
										<tr>
											<td>FRENCH </td>
											<td>DUTCH</td>
											
										</tr>
										 <tr>
											<td>ARABIC</td>
											<td>SPANISH</td>
											
										</tr>
										
									</table>

								</TabPane>
                                <br/>
                                <br/>
                                <div>
                                    <Link to ={"/contact"} className="site-button outline outline-2 button-lg">Contact us</Link>
                                
                                </div>
                                                {/* <TabStyle1></TabStyle1> */}
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <SidebarEducation />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Our Services --> */}
                <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Your Dream Destination</h2>
                                    <h5 className="box-title m-tb0">The dream destination of progress & development will be achieved by imparting latest education to the youth<span className="bg-primary"></span></h5>


                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div>
                        
                <div className="choses-info text-black" >
                                <Counter />
                                
                            </div>
                <Footer />
            </>
        )
    }
}
export default LanguageClasses;
import React,{Component} from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import HomeOwlSlider from './../Element/HomeOwlSlider';
// import HomeOwlSlider2 from './../Element/HomeOwlSlider2';
// import ImgCarouselContent from './../Element/ImgCarouselContent';
import HomeTab from './../Element/HomeTab';
import Counter from './../Element/Counter';
// import PortfolioCarousel from './../Element/PortfolioCarousel';
import TestimonialCarousel from './../Element/TestimonialCarousel';
import FormStyle from './../Element/FormStyle';
import ClientCarousel from '../Element/ClientCarousel';
//Images
//import pic4 from './../../images/about/dreamz-academy-home-about.jpg';
import bgimg from './../../images/background/bg1.jpg';
//import bgimg2 from './../../images/background/bg-map.jpg';
import bgimg3 from './../../images/background/bg1.jpg';


class Homepage extends Component{
		
	componentDidMount() {
		
	}	
	
	render(){
		return(
			<div className="page-wraper">
				<Header />
				<div className="page-content bg-white">
					{/*  Slider Banner */}
					<div className="owl-slider-banner main-slider">
						<HomeOwlSlider />
                        {/* <!-- About Us --> */}
                        <div className="section-full bg-white p-b30">
                            <div className="container">
                                <div className="row about-1">
                                    <div className="icon-bx-wraper col-md-4 bg-white col-lg-4 p-tb30 p-l30 p-r50 fly-box-ho">
                                        <div className="icon-md m-b20">
                                        <span className="icon-cell">
                                            <img src="images/icon/icon3.png" alt="" />
                                        </span>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="dez-tilte"><span className="font-weight-300">Our Educational Services</span></h3>
                                            <p>Dream’z Academy takes pride in building a strong foundation for your learning and trains you with the latest, most efficient resources. Know more about our coaching below. </p>
                                            <ul className="list-arrow-right m-b0">
                                                <li><i className="fa fa-arrow-right"></i>IELTS/CD-IELTS</li>
                                                <li><i className="fa fa-arrow-right"></i>PTE</li>
                                                <li><i className="fa fa-arrow-right"></i>TOEFL</li>
                                                <li><i className="fa fa-arrow-right"></i>Interview Preperation</li>
                                                <li><i className="fa fa-arrow-right"></i>Online Classes</li>
                                                <li><i className="fa fa-arrow-right"></i>Duolingo English Test</li>
                                                <li><i className="fa fa-arrow-right"></i>Spoken English</li> 
                                                <li><i className="fa fa-arrow-right"></i>Life Skills</li>
                                                <li><i className="fa fa-arrow-right"></i>Languages</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="icon-bx-wraper bg-primary p-lr30 about-scale text-white col-md-4 col-lg-4 p-tb30 fly-box-ho">
                                        <div className="icon-md m-b20">
                                        <span className="icon-cell">
                                            <img src="images/icon/icon1.png" alt="" />
                                        </span>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="dez-tilte"><span className="font-weight-300">IELTS/PTE Test Preparation </span></h3>
                                            

Boasting a deeply researched teaching approach curated by experts, Dream’z has set extraordinary benchmarks in IELTS test preparation with remarkable results and top Bands scored. 
<ul className="list-arrow-right m-b0">
<li><i className="fa fa-arrow-right"></i>Comprehensive Practice Material</li>
<li><i className="fa fa-arrow-right"></i>Modern Teaching Environment</li>
<li><i className="fa fa-arrow-right"></i>Separate PC for PTE Students</li>
<li><i className="fa fa-arrow-right"></i>Small Batches for Individual Attention</li>
<li><i className="fa fa-arrow-right"></i>Experienced Faculty</li>
<li><i className="fa fa-arrow-right"></i>Weekly Mock Test</li>
<li><i className="fa fa-arrow-right"></i>Flexible Timing</li>
                                     </ul>
                                        </div>
                                    </div>
                                    <div className="icon-bx-wraper p-l50 p-r30 col-md-4 bg-white col-lg-4 p-tb30 fly-box-ho">
                                        <div className="icon-md m-b20">
                                        <span className="icon-cell">
                                            <img src="images/icon/icon2.png" alt="" />
                                        </span>
                                        </div>
                                        <div className="icon-content">
                                            <h3 className="dez-tilte"><span className="font-weight-300">Our Immigration Services</span></h3>
                                            <p>                                       
Our Visa Experts are here to help you with an honest and transparent working methodology. we make sure you are informed at every step. We deal in various visas like:
 </p>
 <ul className="list-arrow-right m-b0">
<li><i className="fa fa-arrow-right"></i>Study Visa</li>
<li><i className="fa fa-arrow-right"></i>Tourist Visa</li>
<li><i className="fa fa-arrow-right"></i>Super Visa</li>
<li><i className="fa fa-arrow-right"></i>Work Permit</li>
<li><i className="fa fa-arrow-right"></i>PR</li>
<li><i className="fa fa-arrow-right"></i>Spouse Visa</li>

                                     </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us END --> */}
					</div>
                    <hr/>
					{/*  Slider Banner */}
					 <div className="content-block">
                        {/* <!-- About Us --> * /}
                        <div className="section-full bg-white content-inner-1 about-us">
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-7 col-md-8">
                                        <div className="about-box row" >
                                            {/*<div className="col-lg-4">
                                                <h2 className="box-title m-tb0">About Us<span className="bg-primary"></span></h2>
                                                <h4 className="text-gray-dark">We provide unique learning experience</h4>
                                            </div> * /}
                                            <div className="col-lg-12 fly-box-ho">
                                                <h3>Why Us?</h3>
                                                <p className="text-justify">WHY US?
                                                Here at Dream’z we don’t believe in empty promises, we believe in Delivering Results. We promise to give you the best immigration experience.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 col-md-4 about-img" >
                                        <img src={pic4} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
						{/* <!-- Our Services --> * /}
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Your Dream Destination</h2>
                                    <h5 className="box-title m-tb0">The dream destination of progress & development will be achieved by imparting latest education to the youth<span className="bg-primary"></span></h5>


                                </div>
                            </div>
                            <div className="development-box">
                                <div className="container">
                                    <HomeOwlSlider2 />
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> * /}
						{/* <!-- Why Chose Us --> */}
                        <div className="section-full content-inner-1 overlay-primary choseus-tabs  bg-img-fix" style={{ backgroundImage: "url(" + bgimg + ")" }}>
                            <div className="container">
                                <div className="section-head text-white text-center">
                                    <h2 className="box-title m-tb0">Why Choose Dreamz' Academy?<span className="bg-primary"></span></h2>
                                    <p>
Here at Dream’z we don’t believe in empty promises, we believe in Delivering Results. We promise to give you the best immigration experience.
</p>
                                </div>
                            </div>
							<HomeTab />
							<div className="choses-info text-white">
                                <Counter />
                            </div>
						</div>	
						{/* <!-- Why Chose Us End --> */}
						{/* <!-- Our Portfolio --> * /}
                        <div className="section-full content-inner-1 mfp-gallery">
                            <div className="container-fluid">
                                <div className="section-head text-center">
                                    <h4 className="text-gray-dark m-b10">Our Portfolio</h4>
                                    <h2 className="box-title m-tb0">We work to innovate & are proud<br /> of what we've created<span className="bg-primary"></span></h2>
                                    <h5>YOU’LL LOVE TO SEE SOME OF OUR BEST PROJECTS. TAKE A LOOK!</h5>
                                </div>
                                    <PortfolioCarousel />
                            </div>
                        </div>
                        {/ * <!-- Our Portfolio END --> * /}
						{/ * <!-- Testimonials --> */}
                        <div className="section-full content-inner" >
                            <div className="container">
                           
   
    <div class="elfsight-app-894f0b15-877f-4148-b9ea-06e77e12af3a" data-elfsight-app-lazy></div>
                            {/* <TestimonialCarousel/> */}
                            </div>
                        </div>
                        {/* <!-- Testimonials END --> */}
                        <div className="section-full content-inner" >
                            <div className="container">
                                <ClientCarousel/>
                                </div>
                        </div>
						{/* <!-- Get in touch --> */}

                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bgimg3 + ")" }}>
                            <FormStyle />
                        </div>
                        {/* <!-- Get in touch --> */}
						
					</div>
				</div>	
				<Footer />
				
			</div>		
		)
	}
}
export default Homepage;

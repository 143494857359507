import React,{Fragment,  useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

//import test1 from './../../../images/testimonials/pic1.jpg';
//import test2 from './../../../images/testimonials/pic2.jpg';
//import test3 from './../../../images/testimonials/pic3.jpg';

/* const commentBlog = [
	{image: test3, }, {image: test3, }, {image: test3, }, {image: test3, }, {image: test3, }, {image: test3, } 
];          
     */        
const TabStyle1 = (props) => {
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	  
	return(
		<Fragment>			
			
                <div className="row">
					<div className="col-md-12">
						<div className="dlab-tabs product-description tabs-site-button">
							<ul className="nav nav-tabs">
								<li>
									<Link to={"#"} className= {classnames({ active : activeTab === '1'}) + ''} onClick={() => { toggle('1'); }}><i className="fa fa-cog"></i> IELTS</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '2'}) + ''} onClick={()=>{ toggle('2');}}><i className="fa fa-cog"></i> PTE</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '3'}) + ''} onClick={()=>{ toggle('3');}}><i className="fa fa-cog"></i> Spoken English</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '4'}) + ''} onClick={()=>{ toggle('4');}}><i className="fa fa-cog"></i> Online Classes</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '5'}) + ''} onClick={()=>{ toggle('5');}}><i className="fa fa-cog"></i> IELTS Life Skills</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '6'}) + ''} onClick={()=>{ toggle('6');}}><i className="fa fa-cog"></i> Language Classes</Link>
								</li>
								<li>
									<Link to={"#"}	className={classnames({active:activeTab === '7'}) + ''} onClick={()=>{ toggle('7');}}><i className="fa fa-cog"></i> Interview Preparation</Link>
								</li>
							</ul>
						
							<TabContent activeTab={activeTab}>

								<TabPane tabId="1">
									<p className="m-b10">
										IELTS - International English Language Testing System is designed to help you acquire the necessary skills to study, work or live abroad. The test is conducted on Academic or General Training standards and comprises 4 key measures- Listening, Reading, Writing, and Speaking.
									</p>
									<p>
										Dream’z Academy is home to specialized learning resources curated by seasoned trainers with years of experience and expertise. Our teaching methods are detail-oriented, in sync with the latest guidelines, and focused on deep rooted issues in language. 
									</p>
									Call us today to achieve your dream IELTS score!

									<table className="table table-bordered" >
										<tr>
											<td>1 on 1 speaking Labs</td>
											<td>Weekly Exam-Like Mock Tests</td>
											<td>FREE Grammar & Vocabulary Classes</td>
										</tr>
										<tr>
											<td>Latest exam topics and study Material</td>
											<td>Individual Attention to every student</td>
											<td>Extra-Sessions for all modules revision</td>
										</tr>
										
									</table>

								</TabPane>

								<TabPane tabId="2">
								<p>
									PTE - Pearson Test of English is an internationally accredited testing system designed to help abroad aspirants by taking the testing process online and thereby enabling for frequent tests with quicker results and less manual error. PTE also offers helpful guides to help aspirants in their preparation.
								</p>
								<p>
									Here at Dream’z Academy, we have leveraged the best technology to simulate test-like environments and have a reservoir of certified softwares and additional help aids to give the best learning experience to our students in our dedicated PTE lab.
								</p>
								Talk to us now and get started on your PTE path!

									<table className="table table-bordered" >
										<tr>
											<td>Latest testing software</td>
											<td>Optimal strategy for best Scores</td>
											<td>Additional Writing Sessions</td>
										</tr>
										<tr>
											<td>Fully-equipped Hi-Tech Labs</td>
											<td>Grammar & Vocabulary Classes</td>
											<td>Individual Attention to every student</td>
										</tr>
										
									</table>
								</TabPane>

								<TabPane tabId="3">
									<p>
										Spoken English is the art of using the English language in our day to day communications confidently. For International aspirants struggling with the language or students and professionals aiming to become more confident, Spoken English is the ultimate solution.
									</p>
									<p>
										Dream’z Academy is known for its extensive 40 day Spoken English batches which include language training at the most fundamental level including grammar, sentence formation, idioms, phrases and speaking skills
									</p>
									Build confidence & enhance your Language skills with Dream’z

									<table className="table table-bordered" >
										<tr>
											<td>Fundamental Grammar</td>
											<td>Public Speaking</td>
											<td>Confidence Enhancement</td>
										</tr>
										<tr>
											<td>COnversation Skills</td>
											<td>Interview Skills</td>
											<td>Idioms, proverbs & proficient skills</td>
										</tr>
										
									</table>
								</TabPane>	

								<TabPane tabId="4">
									<p>
										Dream’z Academy is a global venture boasting Clients & Students from all over India and the world. Our Online Classes for IELTS, PTE & Language Classes (French, Dutch, Arabic & Spanish) are available for you in every corner of the world. Just because it’s virtual doesn’t mean you are missing out on anything! 
									</p>
									<p>
										We transfer the same level of education and results onto our virtual platforms as well. We host numerous students across the USA, Canada, Australia, New Zealand, and many other countries and help them clear their second round immigrations tests and more.
									</p>
									Achieve your dream score sitting at home today!


									<table className="table table-bordered" >
										<tr>
											<td>Weekly Mock Tests & Latest exams discussions</td>
											<td>Multiple Batches for different time Zones</td>
											<td>1-on-1 Speaking sessions</td>
										</tr>
									</table>
								</TabPane>	

								<TabPane tabId="5">
									<p>
										IELTS Life Skills is an English test for people who need to demonstrate their speaking and listening skills at Levels A1 or B1 of the Common European Framework of Reference for Languages (CEFR). On the UK Home Office’s list of Secure English Language Tests and is available both in the UK and internationally.
									</p>
									<p>
										Here at Dream’z Academy, you’ll find the most extensive library of resources to help you navigate your way through the test. Whether you are a relative of a permanent UK resident, or aspire to remain in the country permanently, or become a citizen, the IELTS Life Skills test can help you get a step closer to your goal.
									</p>
									
									Learn from Leaders, Call us Now!<br/>	

									<table className="table table-bordered" >
										<tr>
											<td>A1 Speaking & Listening</td>
											<td>B1 Speaking & Listening</td>
										</tr>
										<tr>
											<td>Family of a settled person visa</td>
											<td>Remain in UK permanently & Citizenship</td>
										</tr>
										
									</table>
								</TabPane>	

								<TabPane tabId="6">
									<p>
										Want to learn new Languages, but don’t want to attend boring classes with the same old checkboxes and rules? Let us change your perspective and help you enhance your linguistic skills and master various levels in fun & interesting ways.
									</p>
									<p>
										Dream’z Academy is home to experienced trainers skilled in universally sought languages. With dynamic lesson plans and 1-on-1 training sessions, we help you upskill yourself and get certified in all levels from beginners to experienced!
									</p>

									Master Beginner, Intermediate, & Advanced Levels of these Languages!
									<br/>

									<table className="table table-bordered" >
										<tr>
											<td>French</td>
											<td>Dutch</td>
										</tr>
										<tr>
											<td>Arabic</td>
											<td>Spanish</td>
										</tr>
										
									</table>
								</TabPane>	

								<TabPane tabId="7">
									<p>
										Moving to a new country is already daunting enough, not to forget the stressful procedures of going through rounds & rounds of interviews be it for Embassies, universities, or seeking jobs. It can be especially difficult when there are no guides to help you through them.
									</p>
									<p>
										Worry not! You can rely on Dream’z academy to help you prepare for every interview and crush it when the time comes. We back our lesson plans with extensive research and help you succeed with rigorous training and continual practice sessions.
									</p>
									
									Nail interviews with confidence. Call us Now!
									<br/>

									<table className="table table-bordered" >
										<tr>
											<td>Embassy Interviews</td>
											<td>University Interviews</td>
											<td>Job Interviews</td>
										</tr>
										<tr>
											<td>(UK, USA etc.)</td>
											<td>(Admission)</td>
											<td>(India & Abroad)</td>
										</tr>
										
									</table>
								</TabPane>	
							</TabContent>
						</div>	
					</div>
				</div>		
			
		</Fragment>
	)
	
}	
export default TabStyle1;
//https://santamonicaedu.in///
import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ThemeButton from './Element/ThemeButton/ThemeButton';

import Homepage from './Pages/Homepage1';
/*import Index2 from './Pages/Index2';
import Index3 from './Pages/Index3';
import Index4 from './Pages/Index4';
import Index5 from './Pages/Index5';
import Index6 from './Pages/Index6';
import Index7 from './Pages/Index7';*/
import Aboutus1 from './Pages/Aboutus/Aboutus1';
import Aboutus2 from './Pages/Aboutus/Aboutus2';


import IELTS from './Pages/EducationalServices/IELTS';
import CD_IELTS from './Pages/EducationalServices/CD_IELTS';
import PTE from './Pages/EducationalServices/PTE';
import TOEFL from './Pages/EducationalServices/TOEFL';
import SpokenEnglish from './Pages/EducationalServices/SpokenEnglish';
import OnlineClasses from './Pages/EducationalServices/OnlineClasses';
import LifeSkill from './Pages/EducationalServices/LifeSkill';
import LanguageClasses from './Pages/EducationalServices/LanguageClasses';
import DuolingoEnglishTest from './Pages/EducationalServices/DuolingoEnglishTest';
import PasswordEnglishTest from './Pages/EducationalServices/PasswordEnglishTest';
import InterviewPreperation from './Pages/EducationalServices/InterviewPreperation';

import StudyVisa from './Pages/VisaServices/StudyVisa';
import TouristVisa from './Pages/VisaServices/TouristVisa';
import SuperVisa from './Pages/VisaServices/SuperVisa';
import WorkVisa from './Pages/VisaServices/WorkVisa';
import PR from './Pages/VisaServices/PR';
import SpouseVisa from './Pages/VisaServices/SpouseVisa';

import NewsAndEvents from './Pages/NewsAndEvents';

import Error403 from './Pages/Error/Error403';
import Error404 from './Pages/Error/Error404';
import Error405 from './Pages/Error/Error405';
import ComingSoon1 from './Pages/ComingSoon/ComingSoon1';
import UnderMaintenance from './Pages/UnderMaintenance';
import Expertise from './Pages/Expertise';
import ProjectManagement from './Pages/ProjectManagement';
import OurProjects from './Pages/OurProjects';
import ProjectDetails from './Pages/ProjectDetails';
import Team from './Pages/Team';
import TestPreparationTraining from './Pages/TestPreparationTraining';
import Faqs from './Pages/Faqs';
import Login from './Pages/Login';
import Register from './Pages/Register';
import Contact from './Pages/Contact';
import Service from './Pages/Service/Service';
import ServicesDetails from './Pages/Service/ServicesDetails';
import BlogStandard from './Pages/BlogStandard/BlogStandard';
import BlogStandardLeftSidebar from './Pages/BlogStandard/BlogStandardLeftSidebar';
import BlogStandardRightSidebar from './Pages/BlogStandard/BlogStandardRightSidebar';
import BlogClassicGrid from './Pages/BlogClassic/BlogClassicGrid';
import BlogClassicLeftSidebar from './Pages/BlogClassic/BlogClassicLeftSidebar';
import BlogClassicRightSidebar from './Pages/BlogClassic/BlogClassicRightSidebar';
import BlogListLeftSidebar from './Pages/BlogList/BlogListLeftSidebar';
import WhyDreamzAcademy from './Pages/BlogList/WhyDreamzAcademy';
import BlogDetails from './Pages/BlogDetail/BlogDetails';
import ProtfolioFullWidth from './Pages/Protfolio/ProtfolioFullWidth';
import PortfolioGrid2 from './Pages/Protfolio/PortfolioGrid2';
import PortfolioGrid3 from './Pages/Protfolio/PortfolioGrid3';
import PortfolioGrid4 from './Pages/Protfolio/PortfolioGrid4';
 import ShopColumns from './Pages/Shop/ShopColumns';
import ShopColumnsSidebar from './Pages/Shop/ShopColumnsSidebar';

import ShopCart from './Pages/Shop/ShopCart';
import ShopCheckout from './Pages/Shop/ShopCheckout';	
import ShopProductDetails from './Pages/Shop/ShopProductDetails';	

import ShopWishlist from './Pages/Shop/ShopWishlist';
import ShopLogin from './Pages/Shop/ShopLogin';
import ShopRegister from './Pages/Shop/ShopRegister';

import ShortIconBox from './Pages/ShortCode/ShortIconBox';
import ShortCounter from './Pages/ShortCode/ShortCounter';
import ShortPortfolio from './Pages/ShortCode/ShortPortfolio';
import ShortTabs from './Pages/ShortCode/ShortTabs';
import ShortTeam from './Pages/ShortCode/ShortTeam';
import ShortTestimonial from './Pages/ShortCode/ShortTestimonial';
import ShortForm from './Pages/ShortCode/ShortForm';
import ShortAccordions from './Pages/ShortCode/ShortAccordions';

import ScrollToTop from './Element/ScrollToTop';
import StudyAbroadCounselling from "./Pages/StudyAbroadCounselling";
import CourseAdvice from "./Pages/CourseAdvice";
import universityCollegeApplicationProcessing from "./Pages/UniversityCollegeApplicationProcessing";
import StudyInCanada from "./Pages/StudyInCanada";
import StudyInAustralia from "./Pages/StudyInAustralia";
import StudyInNewZealand from "./Pages/StudyInNewZealand";
import StudyInUK from "./Pages/StudyInUK";
import StudyInUSA from "./Pages/StudyInUSA";

import Ielts from "./Pages/Ielts";
import VisaServices from "./Pages/VisaServices";
import LatestResult from "./Pages/Protfolio/ProtfolioFullWidth";

class Routes extends Component{
	render(){
		return(
			// <BrowserRouter basename="/react/demo">
            <BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Homepage} />
                       {/* <Route path='/' exact component={Index5} />*/}
                        <Route path='/company-profile' exact component={Aboutus1} />
                        <Route path='/ourteam' exact component={Aboutus2} />
                        <Route path='/why-prefer-tcm-global' exact component={Expertise} />   {/* slug/slugify **/}
                        <Route path='/team' exact component={Team} />
                        <Route path='/contact' exact component={Contact} />
                        <Route path='/our-team' exact component={ProjectDetails} />
                        <Route path='/why-dreamz-academy' exact component={WhyDreamzAcademy} />
                        <Route path='/test-preparation-training' exact component={TestPreparationTraining} />
                        <Route path='/study-abroad-counselling' exact component={StudyAbroadCounselling} />
                        <Route path='/course-advice' exact component={CourseAdvice} />
                        <Route path='/university-college-application-processing' exact component={universityCollegeApplicationProcessing} />
                        <Route path='/study-in-canada' exact component={StudyInCanada} />
                        <Route path='/study-in-australia' exact component={StudyInAustralia} />
                        <Route path='/study-in-new-zealand' exact component={StudyInNewZealand} />
                        <Route path='/study-in-uk' exact component={StudyInUK} />
                        <Route path='/study-in-usa' exact component={StudyInUSA} />
                        <Route path='/educational-services' exact component={Ielts} />
                        <Route path='/visa-services' exact component={VisaServices}/>
                        <Route path='/latest-result' exact component={LatestResult}/>
                        <Route path='/news-and-events' exact component = {NewsAndEvents}/>
                        {/* <Route path='/index-2' exact component={Index2} />
                        <Route path='/index-3' exact component={Index3} />
                        <Route path='/index-4' exact component={Index4} />
                        <Route path='/index-6' exact component={Index6} />
                        <Route path='/index-7' exact component={Index7} />*/}
                        <Route path='/about-1' exact component={Aboutus1} />
                        <Route path='/about-2' exact component={Aboutus2} />
                        <Route path='/error-403' exact component={Error403} />
                        <Route path='/error-404' exact component={Error404} />
                        <Route path='/error-405' exact component={Error405} />
                        <Route path='/coming-soon-1' exact component={ComingSoon1} />
                        <Route path='/under-maintenance' exact component={UnderMaintenance} />

                        <Route path='/project-management' exact component={ProjectManagement} />
                        <Route path='/our-projects' exact component={OurProjects} />


                        <Route path='/faqs' exact component={Faqs} />
                        <Route path='/login' exact component={Login} />
                        <Route path='/register' exact component={Register} />

                        <Route path='/service' exact component={Service} />
                        <Route path='/services-details' exact component={ServicesDetails} />
                        <Route path='/blog-standard' exact component={BlogStandard} />
                        <Route path='/blog-standard-left-sidebar' exact component={BlogStandardLeftSidebar} />
                        <Route path='/blog-standard-right-sidebar' exact component={BlogStandardRightSidebar} />
                        <Route path='/blog-classic-grid' exact component={BlogClassicGrid} />
                        <Route path='/blog-classic-left-sidebar' exact component={BlogClassicLeftSidebar} />
                        <Route path='/blog-classic-right-sidebar' exact component={BlogClassicRightSidebar} />
                        <Route path='/blog-list-left-sidebar' exact component={BlogListLeftSidebar} />

                        <Route path='/blog-details' exact component={BlogDetails} />
                        <Route path='/portfolio-full-width' exact component={ProtfolioFullWidth} />
                        <Route path='/portfolio-grid-2' exact component={PortfolioGrid2} />
                        <Route path='/portfolio-grid-3' exact component={PortfolioGrid3} />
                        <Route path='/portfolio-grid-4' exact component={PortfolioGrid4} />
                        <Route path='/shop-columns' exact component={ShopColumns} />
                        <Route path='/shop-columns-sidebar' exact component={ShopColumnsSidebar} />
						
                        <Route path='/shop-cart' exact component={ShopCart} />
                        <Route path='/shop-checkout' exact component={ShopCheckout} />
                        <Route path='/shop-product-details' exact component={ShopProductDetails} />
                        <Route path='/shop-wishlist' exact component={ShopWishlist} />
                        <Route path='/shop-login' exact component={ShopLogin} />
                        <Route path='/shop-register' exact component={ShopRegister} />
						
                        <Route path='/short-icon-box' exact component={ShortIconBox} />
                        <Route path='/short-counter' exact component={ShortCounter} />
                        <Route path='/short-portfolio' exact component={ShortPortfolio} />
                        <Route path='/short-tabs' exact component={ShortTabs} />
                        <Route path='/short-team' exact component={ShortTeam} />
                        <Route path='/short-testimonial' exact component={ShortTestimonial} />
                        <Route path='/short-form' exact component={ShortForm} />
                        <Route path='/short-accordions' exact component={ShortAccordions} />	

                        <Route path = '/ielts' exact	component = {IELTS}/>	
                        <Route path = '/cd-ielts' exact	component = {CD_IELTS}/>	
                        <Route path = '/pte' exact component = {PTE}/>
                        <Route path = '/toefl' exact component = {TOEFL}/>
                        <Route path = '/spoken-english' exact component = {SpokenEnglish}/>
                        <Route path = '/online-classes' exact component = {OnlineClasses}/>
                        <Route path = '/life-skill' exact component = {LifeSkill}/>
                        <Route path = '/language-classes' exact component = {LanguageClasses}/>
                        <Route path = '/duolingo-english-test' exact component = {DuolingoEnglishTest}/>
                        <Route path = '/password-english-test' exact component = {PasswordEnglishTest}/>
                        <Route path = '/interview-preperation' exact component = {InterviewPreperation}/>

                        <Route path = '/study-visa' exact component = {StudyVisa}/>
                        <Route path = '/tourist-visa' exact component = {TouristVisa}/>
                        <Route path = '/super-visa' exact component = {SuperVisa}/>
                        <Route path = '/work-visa' exact component = {WorkVisa}/>
                        <Route path = '/pr' exact component = {PR}/>
                        <Route path = '/spouse-visa' exact component = {SpouseVisa}/>



					</Switch>
                </div>
				<ScrollToTop />
				<ThemeButton />
            </BrowserRouter>	
		)
	}
	
}

export default Routes;
import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';

//import bgimg from './../../images/background/bg2.jpg';
import PageTitle from "../Layout/PageTitle";
import Map from '../../Components/Map/Map';

class Contact extends Component {

    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Contact Us' activeMenu='Contact Us' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                    {/* <!-- inner page banner --> */}
                    <div >
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 col-xs-4">
                                    <div className="row text-black">
                                        <div className="col-lg-12 col-md-6 m-b30 fly-box-ho" >
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                        Jalandhar Office:
													</h5>
                                                    <p> #25, 26 Bansal Tower, 1st Floor above Subway, Pathankot Chowk, Jalandhar </p>
                                                    <h6 className="m-b15 font-weight-400"><i className="ti-alarm-clock"></i> Office Hours</h6>
                                                    <p className="m-b0">Mon To Sat - 09.30am - 06.30pm</p>
                                                    <p>Sunday - Close</p>


                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-mobile"></i></span>
                                                        Phone Numbers
                                                    </h5>
                                                    <p>+91 79017 11757 <br/></p>

                                                </div>
                                            </div>
                                        </div>
                                                <div className="col-lg-12 col-md-6 m-b30 fly-box-ho" >
                                                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                        <div className="icon-content">
                                                            <h5 className="dlab-tilte">
                                                                <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                                Kapurthala Office:
                                                            </h5>
                                                            <p> #1, Aman Nagar, Ground Floor, Abhay Tower, Kapurthala </p>
                                                            <h6 className="m-b15 font-weight-400"><i className="ti-alarm-clock"></i> Office Hours</h6>
                                                            <p className="m-b0">Mon To Sat - 09.30am - 06.30pm</p>
                                                            <p>Sunday - Close</p>

                                                            <h5 className="dlab-tilte">
                                                                <span className="icon-sm text-primary text-left"><i             className="ti-mobile"></i></span>
                                                                Phone Numbers
                                                            </h5>
                                                            <p>+91 82830 17817</p>
                                                        </div>
                                                    </div>
                                                </div>







                                                
                                                <div className="col-lg-12 col-md-6 m-b30 fly-box-ho" >
                                                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                        <div className="icon-content">
                                                            <h5 className="dlab-tilte">
                                                                <span className="icon-sm text-primary text-left"><i className="ti-location-pin"></i></span>
                                                               Una Office:
                                                            </h5>
                                                            <p> Nangal Road, opp. Govt Girls College, Una</p>
                                                            <h6 className="m-b15 font-weight-400"><i className="ti-alarm-clock"></i> Office Hours</h6>
                                                            <p className="m-b0">Mon To Sat - 09.30am - 06.30pm</p>
                                                            <p>Sunday - Close</p>

                                                            <h5 className="dlab-tilte">
                                                                <span className="icon-sm text-primary text-left"><i             className="ti-mobile"></i></span>
                                                                Phone Numbers
                                                            </h5>
                                                            <p>+91 80913-13299
</p>
                                                        </div>
                                                    </div>
                                                </div>
                                        <div className="col-lg-12 col-md-6 m-b30 fly-box-ho">
                                            <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte">
                                                        <span className="icon-sm text-primary text-left"><i className="ti-email"></i></span>
                                                        E-mail
													</h5>
                                                    <p className="m-b0">info@dreamz.academy</p>
                                                    <p className="m-b0">admin@dreamz.academy</p>
                                                    <p className="m-b0">support@dreamz.academy</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-8 col-xs-8">
                                    
                                    <form className="inquiry-form dzForm"  action="script/contact.php">
                                        
                                    <h3 className="box-title m-t0 m-b10">Contact Us <span className="bg-primary"></span></h3>
                                    HELLO!<br/>
                                    Nice to meet you!<br/>
                                    <p/>
                                    We can’t wait to talk to you, and you don’t have to either!<br/>
                                    Fill out the form so we can get in touch with you ASAP.
                                        <div className="dzFormMsg"></div>

                                        
                                        <p></p>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                        <input name="dzName" type="text" required className="form-control" placeholder="First Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                        <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="Phone" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                        <input name="dzEmail" type="email" className="form-control" required placeholder="Your Email Id" />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                        <textarea name="dzMessage" rows="4" className="form-control" required placeholder="Type your message here..."></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <div className="input-group">
                                                        <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                        <input className="form-control d-none" data-recaptcha="true" required data-error="Please complete the Captcha" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <button name="submit" type="submit" value="Submit" className="site-button button-lg"> <span>Send Message!</span> </button>
                                            </div>
                                        </div>
                                        <br/>
                                        Can’t wait?
Reach us directly if your needs are pressing, time-sensitive, or you just can’t wait to join
the leading name in Immigration &amp; Education.
                                    </form>
                                    

                              
                          
                               </div>
                    
                    {/* <!-- inner page banner END --> */}
                </div></div>    </div>  </div>  </div>
                    
                
                {/* <Map/> */}
                <Footer />
            </>
        )
    }
}
export default Contact;
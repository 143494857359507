import React, {Component,useState,useEffect,Fragment } from 'react';
import { Link } from 'react-router-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import {SRLWrapper, useLightbox} from 'simple-react-lightbox'; 
import Header from '../Layout/header3';
import Footer from '../Layout/footer4';
import PageTitle from '../Layout/PageTitle';
import Masonry from 'react-masonry-component';
import Counter from '../Element/Counter';

//images
//import bnr1 from './../../../images/banner/bnr1.jpg';
// import box1 from './../../../images/dreamz/pic2.jpg';
// import box2 from './../../../images/dreamz/pic3.jpg';
// import box3 from './../../../images/dreamz/pic4.jpg';
// import box4 from './../../../images/dreamz/pic5.jpg';
// import box5 from './../../../images/dreamz/pic6.jpg';
// import box6 from './../../../images/dreamz/pic7.jpg';
// import box7 from './../../../images/dreamz/pic8.jpg';
// import box8 from './../../../images/dreamz/pic9.jpg';
// import box9 from './../../../images/dreamz/pic10.jpg';
// import box10 from './../../../images/dreamz/pic11.jpg';
// import box11 from './../../../images/dreamz/pic12.jpg';
// import box12 from './../../../images/dreamz/pic13.jpg';
// import box13 from './../../../images/dreamz/pic14.jpg';
// import box14 from './../../../images/dreamz/pic15.jpg';
// import box15 from './../../../images/dreamz/pic16.jpg';
// import box16 from './../../../images/dreamz/pic17.jpg';
// import box17 from './../../../images/dreamz/pic18.jpg';
// import box18 from './../../../images/dreamz/pic19.jpg';
// import box19 from './../../../images/dreamz/pic20.jpg';
// import box20 from './../../../images/dreamz/pic21.jpg';
// import box21 from './../../../images/dreamz/pic22.jpg';
// import box22 from './../../../images/dreamz/pic23.jpg';
// import box23 from './../../../images/dreamz/pic24.jpg';
// import box24 from './../../../images/dreamz/pic25.jpg';
// import box25 from './../../../images/dreamz/pic26.jpg';

import box26 from '../../images/dreamz/pic27.jpg';
import box27 from '../../images/dreamz/pic28.jpg';
import box28 from '../../images/dreamz/pic29.jpg';
//import box29 from '../../images/dreamz/pic30.jpg';
import box30 from '../../images/dreamz/pic31.jpg';
import box31 from '../../images/dreamz/pic32.jpg';
import box32 from '../../images/dreamz/pic33.jpg';
import box33 from '../../images/dreamz/pic34.jpg';
import box34 from '../../images/dreamz/pic35.jpg';
// import box35 from '../../images/dreamz/pic36.jpg';
// import box36 from '../../images/dreamz/pic37.jpg';
// import box37 from '../../images/dreamz/pic38.jpg';
// import box38 from '../../images/dreamz/pic39.jpg';
// import box39 from '../../images/dreamz/pic40.jpg';


const imageBlog = [
	{ Large: box26 , tag: ['IDP'] },
	{ Large: box27 , tag: ['IDP'] }, 
	{ Large: box28 , tag: ['IDP'] }, 
//	{ Large: box29 , tag: ['Visa Approved'] },
	{ Large: box30 , tag: ['IDP'] }, 
	{ Large: box31 , tag: ['IDP'] }, 
	{ Large: box32 , tag: ['IDP'] }, 
	{ Large: box33 , tag: ['IDP'] }, 
	{ Large: box34 , tag: ['IDP'] }, 
//	{ Large: box35 , tag: ['Visa Approved'] }, 
//	{ Large: box36 , tag: ['Visa Approved'] }, 
//	{ Large: box37 , tag: ['Visa Approved'] }, 
//	{ Large: box38 , tag: ['Visa Approved'] },
//	{ Large: box39 , tag: ['Visa Approved'] },	
]

//Light Gallery on icon click 
const Iconimage = props => {
	const { openLightbox } = useLightbox()
	return (
    <Link  to={"#"} onClick={() => openLightbox(props.imageToOpen)} className="mfp-link portfolio-fullscreen" >
      <i className="ti-fullscreen icon-bx-xs"></i>
    </Link>
  )
}

// Masonry section
const masonryOptions = {                                          
    transitionDuration: 0
};

const imagesLoadedOptions = { background: '.my-bg-image-el' }
// Masonry section end

const TagLi = ({name, handlesettag, tagActive}) => {                                                               
	return ( 
			
		<li className={` tag ${tagActive ? 'btn active' : 'btn'}` } onClick={() => handlesettag(name)} >
			<input type="radio" />
			<Link to={"#"} className="site-button-secondry radius-sm" ><span>{name} {''}</span> </Link>
		</li> 
	);
};


class NewsAndEvents extends Component{
	render(){
		return(
			<Fragment>	
			<Header />
			<div className="page-content bg-white">
				{/*  banner  */}
				<div className="bg-black"  >
                        <PageTitle motherMenu='News And Events' activeMenu='News And Events' style={{ padding:"5px" }} />
                    </div>
				{/*  Section-1 Start  */}
				<PortfolioItem />
				
			</div>
			<Footer/>
			</Fragment>			
		)
	}
} 
function PortfolioItem(){
	const [tag, setTag] = useState('All');
	const [filteredImages, setFilterdImages] = useState([]);
	
	     useEffect( () => {
	 	tag === 'All' ? setFilterdImages(imageBlog) : setFilterdImages(imageBlog.filter( image => image.tag.find(element => element === tag)))
	 }, [tag])	
	
	return(
		<>
			<div className="section-full content-inner-1 portfolio text-uppercase">		
				<div className="site-filters clearfix center  m-b40">
					 <ul className="filters" data-toggle="buttons">
						<TagLi name="All"  handlesettag={setTag} tagActive={ tag === 'All' ? true : false }	/>
						<TagLi name="IDP"  handlesettag={setTag} tagActive={ tag === 'IDP' ? true : false }	/>
						
					</ul> 
				</div>
				<SimpleReactLightbox>
					<SRLWrapper >
						<div className="clearfix">
							<ul id="masonry" className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery port-style1">
								<Masonry
									className={'my-gallery-class'} // default ''
									options={masonryOptions} // default {}
									disableImagesLoaded={false} // default false
									updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
									imagesLoadedOptions={imagesLoadedOptions} // default {}
								>
									{filteredImages.map((item, index)=>(
										<li className="web design card-container col-lg-3 col-md-6 col-xs-12 col-sm-6 p-a0" key={index}>
											<div className="dlab-box dlab-gallery-box">
												<div className="dlab-media dlab-img-overlay1 dlab-img-effect"> 
													<img src={item.Large} alt="" />
													<div className="overlay-bx">
														<div className="overlay-icon align-b text-white text-left">
															<div className="text-white text-left port-box">
																
																<Iconimage />
															</div>
														</div>
													</div>
												</div>
											</div>
										</li>
									))}	
								</Masonry>	
							</ul>
						</div>
					</SRLWrapper>
				</SimpleReactLightbox>	
				<Counter/>
			</div>
		</>
	);
}
export {box26, box27, box28, box30, box31, box32, box33, box34, imageBlog};
export default NewsAndEvents;

import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
//import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class StudyInAustralia extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Study in Australia' activeMenu='Study in Australia' style={{ padding:"5px" }} />
                    </div>
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Australia</Link></h4>
                                            </div>
                                            <div className="et_pb_text_inner">
                                                <p className="text-justify">Australia is the third most popular study destination for international students after the USA and the UK. The country has some of the best universities in the world thanks to the top class education system and highest quality teaching. Besides, Australian universities offer a wide variety of study programs and courses for international students to choose from. Dreamz’ Academy's experienced Australia study abroad consultants in Kerala can help you to choose the right program for you.</p>
                                                <p className="text-justify">Australia has around 1100 institutions of higher education offering numerous courses in various streams. These institutions give priority to research and innovation and are equipped with state-of-the-art laboratories and modern classrooms. Another major factor that makes Australia is the affordable cost of education and living. Compared to countries like the UK, education in Australia comes with a cheaper price tag.</p>
                                                <p className="text-justify">Australia gives an option for international students to earn while they learn. Doing part-time job helps a student to meet his/her study expenses and earn significant work experience that would prove useful in their future career. Besides, a number of scholarships instituted by both the government and the universities are available for international students.</p>
                                                <p className="text-justify">The employability rate among students graduated from Australian universities is significantly high. They earn comparatively higher salaries and impress employers with their knowledge and competence.</p>
                                                <p className="text-justify">For all study programs in Australia, a student will be applying for a subclass 500 student visa. This visa to study in Australia can be both short-term and long-term. The students who opt for a course with a duration of fewer than six months can apply for a short-term visa, while the students who want to study a three-year course must apply for a long-term visa. An Australian student visa does not have an age limit. After graduation, a student can apply for a two-year post-study work visa if they choose. With this, they can earn a two-year work Australian work experience and even try to get sponsorship.</p>
                                                <p className="text-justify">In short, Australia is one of the best places to study, which would ensure you an internationally recognized degree and a bright future. If you are interested to study abroad in Australia, don’t hesitate to approach Dreamz' Academy,  a global study abroad consultants in Punjab, and overseas education consultants. Our expert   Australia study abroad consultants in Kerala  can assist you throughout the process.</p>
                                                <p>If you wish to study in Australia, for any assistance visit or call Dreamz' Academy, a global study abroad consultants in Punjab.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1"></div>
                                {/* Main Blog List Sidebar End*/}
                                <div className="col-lg-3 sticky-top fly-box-ho">
                                    <Sidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default StudyInAustralia;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
/* import ImgCarousel from './../Element/ImgCarousel';
import ClientCarousel from './../Element/ClientCarousel';
//import Counter from './Elements/'

//Images 
import bnr1 from './../../images/banner/bnr2.jpg'; */
//import rakesh from './../../images/about-us/rakesh.png';
//import neetu from './../../images/about-us/neetu.png';


import mem1 from './../../images/our-work/neetu-prashar-CEO.jpg';
import mem2 from './../../images/our-work/rakesh-prasher-COO.jpg';

import mem9 from './../../images/our-work/rakesh-prashar-COO.jpg';

import mem3 from './../../images/our-work/jaskaran-kaur.jpg';
import mem4 from './../../images/our-work/madhuri.jpg';
import mem5 from './../../images/our-work/sofia-kanwar.jpg';
import mem6 from './../../images/our-work/jaspreet-kaur.jpeg';
//import mem7 from './../../images/our-work/jaspreet.jpeg';
import mem8 from './../../images/our-work/ankita-bhandari.jpg';
import mem10 from './../../images/our-work/bhumika.jpeg';
import mem11 from './../../images/our-work/ranjeet-kaur.png';
import mem12 from './../../images/our-work/kumar-gaurav.jpeg';
import mem13 from './../../images/our-work/dr-parul-saini-una.jpeg';


const imageblog =[
	{image: mem1,title:'Founder & CEO',  body:'Neetu Prashar', designation:'MA (English)',exp:'10+',years:'Years'},
	{image: mem2,title:'Co-Founder & COO', body: 'Rakesh Prashar', designation:'LLB, MBA',exp:'22+',years:'Years'},

	{image: mem12,title:'Relocation Expert', body: 'Kumar Gaurav', designation:'M.Com, MBA',exp:'20+',years:'Years'},
	{image: mem13,title:'Center Head, Una', body:'Dr. Parul Saini', designation:'Ph.D',exp:'8+',years:'Years'},{image: mem3,title:'HOD IELTS', body: 'Jaskaran Kaur',designation:'M Com',exp:'10+',years:'Years'},
    {image: mem4,title:'HOD PTE', body: 'Madhuri', designation:'MCA',exp:'5+',years:'Years'},
    {image: mem5,title:'Senior IELTS Trainer ', body: 'Sofia Kanwar', designation:'MBA',exp:'8+',years:'Years'},
    {image: mem6,title:'In-charge Speaking ', body: 'Jaspreet Kaur', designation:'Master in Economics Language Trainer (French)',exp:'5+',years:'Years'},
    //{image: mem7,title:'HOD Admin', body: 'Jaspreet Kaur', designation:'BCA',exp:'6+',years:'Years'},
    {image: mem8,title:'Visa Department ', body:'Ankita Bhandari', designation:'MCA',exp:'3+',years:'Years'},
    {image: mem11,title:'Languages', body:'Ranjit Kaur', designation:'PhD Translation and Interpretation',exp:'10+',years:'Years'},
    {image: mem10,title:'Front Office', body:'Bhumika', designation:'Bachelors in Computers',exp:'7+',years:'Years'},
   

];
class ProjectDetails extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    {/*<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>*/}
                    {/*    <PageTitle motherMenu='Project Details' activeMenu='Project Details' />  */}
                    {/*</div>*/}
                    <div className="bg-black"  >
                        <PageTitle motherMenu='Our Team' activeMenu='Our Team' style={{ padding:"5px" }} />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Project Details --> */}
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-9 col-md-9 m-b20  fly-box-ho"><p>&nbsp;</p>
                                        <h2 className="m-b10 m-t0">Neetu Prashar</h2>
                                        <p className="m-b10">Founder & CEO</p>
                                        <h5 className="m-b10 m-t0">Prashar’s Dream’z Academy Of Languages Pvt. Ltd</h5>
                                        <p className="m-b10 text-justify">I wholeheartedly welcome you to Dream’z Academy where we nurture a warm and positive environment for you to alleviate your aspirations. I pledge to educate you all to the best of my abilities and empower you with Confidence, Critical thinking abilities, and Knowledge to actualize your overseas ambitions.
	
    20 years of my life I’ve dedicated towards educating young minds and helping build their future one step at a time. I am grateful for being able to impact thousands of students and being an encouraging presence in their life. I hope I get to do the same for you!
    </p>

                                        {/*<Link to={"#"} className="site-button radius-xl outline">Read More <i className="fa fa-long-arrow-right"></i></Link>*/}
                                    </div>
                                    <div className="col-lg-3 col-md-3">
										<img src={mem1} alt="Dreamz' Academy"/>
                                    </div>
                                </div>

                            </div>



                            <div className="container">
                                <div className="row m-b40">
                                <div className="col-lg-3 col-md-3">
                                    <img src={mem9} />
                                </div>
                                    <div className="col-lg-9 col-md-9 m-b20 fly-box-ho"><p>&nbsp;</p>
                                        <h2 className="m-b10 m-t0">Rakesh Prashar</h2>
                                        <p className="m-b10">Co-Founder & COO</p>
                                        <h5 className="m-b10 m-t0">Prashar’s Dream’z Academy Of Languages Pvt. Ltd</h5>
                                        <p className="m-b10 text-justify">I have practised law for the better part of my life, but the contentment and fulfilment I experience after enabling people to go to their dream destination is unparalleled. In all my years working in immigration, I have brought countless smiles on aspirants’ faces and some tears of joy in their relatives' eyes.

Here at Dream’z Academy, I pledge to provide you the best guidance with utmost transparency, honesty and integrity. Your dream of studying/working/settling abroad is my mission and I promise to give it my all!
</p>

                                        {/*<Link to={"#"} className="site-button radius-xl outline">Read More <i className="fa fa-long-arrow-right"></i></Link>*/}
                                    </div>

                                </div>

                            </div>


                        </div>
                    </div>

                    <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-uppercase m-b10">Our Team</h2>
                                    <p> </p>
                                </div>
                                <div className="row text-center">
									{imageblog.map((item,index)=>(
										<div className="col-lg-3 col-md-3 col-sm-9 m-b20" key={index}>
											<div className="dlab-box">
												<div className="dlab-media dlab-img-effect  imgbrdr shadow" > 
                                                <Link to ={"#"}><img src={item.image} alt="" /></Link> 
                                                </div>
												<div className="dlab-info p-a10 border-1 bg-white">
													<h1 className="dlab-title m-t0">
                                                        <div class="itemexp">{item.exp}</div>
                                                        <div class="itemyears">{item.years}</div>
                                                        <div class="itembody">{item.body}</div>
                                                        <div class="itemtitle">{item.title}</div>
                                                        <div class="itemdesignation">{item.designation}</div>
                                                    </h1>
													{/*<h4><Link to ={"#"}>{item.body}</Link></h4> */}
												</div>
                                            </div>

                                            
										</div>
									))}
                                </div>
                            </div>

                    {/* <!-- contact area END --> */}
                </div>
                <Footer />
            </>
        )
    }
}
export default ProjectDetails;